import { useState, useEffect, useCallback } from "react";
import config from "../variable";
import { useTranslation } from "react-i18next";
import { isISODate, isISODateTime } from "../utils";
import { APIResponse } from '../Types/API';

interface RequestOptions {
  method?: "GET" | "DELETE" | "PUT" | "POST";
  body?: unknown;
  queryParams?: Record<string, unknown>;
  disable?: boolean;
  deps?: unknown[];
}

interface ApiResponse<T> {
  data: T | null;
  error: Error | APIResponse<unknown> | null;
  isLoading: boolean;
  reload: () => void;
}

export const useApi = <T>(
  url: string,
  { queryParams, method, body, disable, deps = [] }: RequestOptions = {}
): ApiResponse<T> => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [key, setKey] = useState<number>(0);
  const { i18n } = useTranslation();

  const jwt = localStorage.getItem("jwt") ?? "";
  const abortController = new AbortController();

  const doRequest = useCallback(async () => {
    if (disable) {
      return;
    }

    const queryString = queryParams
      ? "?" +
        Object.keys(queryParams)
          .reduce((acc: string[], key) => {
            const value = queryParams[key];
            if (value !== undefined && value !== null) {
              acc.push(`${key}=${value}`);
            }
            return acc;
          }, [])
          .join("&")
      : "";

    try {
      const response = await fetch(`${config.api}${url}${queryString}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
          "X-Targeted-Language": i18n.language,
        },
        method,
        mode: "cors",
        credentials: "include",
        signal: abortController.signal,
        body: body ? JSON.stringify(body) : undefined,
      });

      const result = await response.text();

      if (!response.ok) {
        console.error(`HTTP error! Status: ${response.status}`);
        setError(JSON.parse(result));
      } else {
        setData(
          JSON.parse(result, (key, value) => {
            if (typeof value === "string") {
              if (isISODateTime(value)) {
                return new Date(value);
              } else if (isISODate(value)) {
                return new Date(value);
              }
            }
            return value;
          }) as T
        );
      }
    } catch (error) {
      setError(error as Error);
    } finally {
      setIsLoading(false);
    }
    /* eslint react-hooks/exhaustive-deps: 0 */
  }, [queryParams, i18n.language, disable]);

  useEffect(() => {
    doRequest();
    /* eslint react-hooks/exhaustive-deps: 0 */
  }, [key, disable, i18n.language, ...deps]);

  const reload = () => {
    setIsLoading(true);
    setKey((prevKey) => prevKey + 1);
  };

  return { data, error, isLoading, reload };
};
