import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { translationSyncApi } from "./translationSync/translationSync.api";

export const store = configureStore({
  reducer: combineReducers({
    [translationSyncApi.reducerPath]: translationSyncApi.reducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(translationSyncApi.middleware),
});
