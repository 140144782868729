import clsx from "clsx";
import { XCircle } from "react-feather";
import { Modal as NewModal } from "../NewModal/Modal";

interface ModalProps {
  title: string;
  display: boolean;
  setDisplay: (v: boolean) => void;
  width?: string;
  height?: string;
  bgColor?: string;
  children?: any;
  titleClass?: string;
  overflow?: boolean;
  overflowX?: boolean;
}

/** @deprecated
 * @link {@link NewModal} to use instead
 */
export default function Modal(props: ModalProps) {
  const close = () => {
    props.setDisplay(false);
  };
  let css = clsx(
    "fixed left-0 top-0 z-30 flex h-full w-full items-center justify-center bg-white bg-opacity-40 backdrop-blur-lg",
    !props.display && "hidden"
  );
  let cssWrapper = clsx(
    "flex h-full items-center",
    props.width || "w-5/6",
    props.overflowX !== true && "overflow-x-hidden"
  );
  let cssBody = clsx(
    "max-h-9/10 flex w-full flex-col rounded drop-shadow",
    props.height,
    props.bgColor || "bg-white"
  );
  let cssContent = clsx(
    "custom-scrollbar m-1 max-h-full grow",
    props.overflow ? "overflow-y-visible" : "overflow-y-auto"
  );
  let titleCSS = clsx(props.titleClass || "grow text-center text-xl");
  return (
    <>
      {props.display && (
        <div
          className={css}
          onClick={close}
        >
          <div className={cssWrapper}>
            <div
              className={cssBody}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={"flex w-full items-center justify-between p-4"}>
                <h2 className={titleCSS}>{props.title}</h2>
                <button onClick={close}>
                  <XCircle className={"no-fill"} />
                </button>
              </div>
              <div className={cssContent}>{props.children}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
