import React from "react";
import "./index.css";
import App from "./Components/App/App";
import { BrowserRouter } from "react-router-dom";
import { cryptrConf } from "./variable";
import { createRoot } from "react-dom/client";
import { ApiStatusStateProvider } from "@kamae-apps/shared/Hooks/useApiStatus/Context";
import { Provider as Store } from "react-redux";
import { store } from "./store/store";
import { CryptrProvider } from "@cryptr/cryptr-react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";

const root = createRoot(document.getElementById("root")!!);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <CryptrProvider {...cryptrConf}>
          <Store store={store}>
            <ApiStatusStateProvider>
              <App />
            </ApiStatusStateProvider>
          </Store>
        </CryptrProvider>
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);
