import config from "./variable";
import { APIError, APIRequest } from "./Types/API";
import { v4 } from "uuid";
import { externalSetApiStatus } from "./Hooks/useApiStatus/Context";

export function getBorderColor(
  className?: string,
  def: string = "border-black"
) {
  const reg = new RegExp("border-[a-z0-9-]+");
  let res = reg.exec(className ?? "");
  if (res !== null) {
    def = res[0];
  }
  return def;
}

interface ApiRequestOption {
  method?: string;
  body?: APIRequest;
  name?: string;
  headers?: Record<string, string>;
}

interface ApiRequestRawOption {
  method?: string;
  body?: BodyInit;
  name?: string;
  headers?: Record<string, string>;
}

interface ApiRequestSaver {
  id: string;
  controller: AbortController;
}

let apiRequestId: Map<string, ApiRequestSaver> = new Map();

export async function apiRequestRaw<T>(
  url: string,
  type: string,
  options?: ApiRequestRawOption
): Promise<T | null> {
  const requestName = options?.name ?? url.split("?")[0];
  const currentId = v4();
  const controller = new AbortController();
  if (apiRequestId.has(requestName)) {
    apiRequestId.get(requestName)!!.controller.abort();
  }
  apiRequestId.set(requestName, { id: currentId, controller: controller });
  let headers = new Headers(options?.headers);
  let jwt = localStorage.getItem("jwt") ?? "";
  headers.append("Authorization", "Bearer " + jwt);
  headers.append("Content-Type", type);
  const init: RequestInit = {
    method: options?.method ?? "GET",
    mode: "cors",
    body: options?.body,
    headers: headers,
    credentials: "include",
    signal: controller.signal,
  };
  let response: Response;
  try {
    response = await fetch(config.api + url, init);
  } catch (e) {
    // Whether it was canceled: "DOMException: The user aborted a request."
    const isRequestCanceled = e instanceof DOMException;
    if (!isRequestCanceled) {
      console.error(`Request failed: "${options?.method ?? "GET"} ${url}".`, e);
      externalSetApiStatus({ code: -1, message: "No network" });
    }
    return null;
  }
  externalSetApiStatus({ code: response.status, message: response.statusText });
  if (response.status.toString()[0] !== "2") {
    let test;
    try {
      test = await response.json();
    } catch (e) {
      if (response.status === 401) {
        throw new APIError(response.status, response.statusText, undefined);
      }
      throw response;
    }
    if (
      Object.keys(test).includes("code") &&
      Object.keys(test).includes("message")
    ) {
      throw new APIError(test.code, test.message, test.data);
    } else {
      throw response;
    }
  }
  if (response.status === 204) {
    return null;
  }
  try {
    if (currentId !== apiRequestId.get(requestName)?.id) {
      return null;
    }
    apiRequestId.delete(requestName);
    const responseText = await response.text();
    return JSON.parse(responseText, (key, value) => {
      if (typeof value === "string") {
        if (isISODateTime(value)) {
          return new Date(value);
        } else if (isISODate(value)) {
          return new Date(value);
        }
      }
      return value;
    });
  } catch (e) {
    return null;
  }
}

export async function apiRequest<T>(
  url: string,
  options?: ApiRequestOption
): Promise<T | null> {
  return apiRequestRaw<T>(url, "application/json", {
    method: options?.method,
    body: JSON.stringify(options?.body),
    name: options?.name,
    headers: options?.headers,
  });
}

export function clone<T>(obj: T, newValues?: any): T {
  const tmp = Object.assign({}, obj);
  if (newValues !== null) {
    Object.assign(tmp, newValues);
  }
  return tmp;
}

export function basicCompare<T extends object>(object1: T, object2: T) {
  for (const name in object1) {
    if (!(name in object2)) {
      return false;
    }
    if (
      typeof object1[name] === "object" &&
      typeof object2[name] === "object"
    ) {
      if (!basicCompare(object1[name] as object, object2[name] as object)) {
        return false;
      }
    } else {
      if (object1[name] !== object2[name]) {
        return false;
      }
    }
  }
  return true;
}

export function dateAfter(date1: Date, date2: Date) {
  if (date1.getFullYear() > date2.getFullYear()) {
    return true;
  }
  if (date1.getMonth() > date2.getMonth()) {
    return true;
  }
  if (date1.getDate() > date2.getDate()) {
    return true;
  }
  return false;
}

export const emailPattern =
  /^(?:(.+) <)?(\w+[\w.-]*\w+)@(?:((?:\w+-)*\w+)\.)?(\w+-*\w*.\w{2,6})>?$/;

export function isValidDate(date: string, reverseMonth?: boolean): boolean {
  const regEx =
    reverseMonth === true
      ? /([0-1]?[0-9])\/([0-3]?[0-9])\/(20[0-9]{2})/
      : /([0-3]?[0-9])\/([0-1]?[0-9])\/(20[0-9]{2})/;
  if (regEx.test(date)) {
    const data = regEx.exec(date)!!;
    const day = parseInt(reverseMonth === true ? data[2] : data[1]);
    const month = parseInt(reverseMonth === true ? data[1] : data[2]) - 1;
    const year = parseInt(data[3]);
    return formatDate(dateToUTC(new Date(year, month, day))) === date;
  } else {
    return false;
  }
}

export function getDateFromUser(date: string, reversMonth?: boolean): Date {
  const tmp = date.split("/");
  const d =
    reversMonth === true
      ? new Date(parseInt(tmp[2]), parseInt(tmp[0]) - 1, parseInt(tmp[1]))
      : new Date(parseInt(tmp[2]), parseInt(tmp[1]) - 1, parseInt(tmp[0]));
  const res = dateToUTC(d);
  res.setUTCHours(0, 0, 0);
  return res;
}

export function formatDate(date: Date, reverseMonth?: boolean): string {
  const month =
    date.getUTCMonth() < 9
      ? "0" + (date.getUTCMonth() + 1)
      : date.getUTCMonth() + 1;
  const day =
    date.getUTCDate() < 10 ? "0" + date.getUTCDate() : date.getUTCDate();
  if (reverseMonth === true) {
    return `${month}/${day}/${date.getUTCFullYear()}`;
  } else {
    return `${day}/${month}/${date.getUTCFullYear()}`;
  }
}

export function dateToUTC(date: Date): Date {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
}

export function ISODateToDate(value: string): Date {
  const date = value.split("T")[0].split("-");
  const hour = value.split("T")[1].split(".")[0].split(":");
  const res = new Date();
  // We need to decrement month because JS month are based on 0 not 1
  res.setUTCFullYear(
    parseInt(date[0]),
    parseInt(date[1]) - 1,
    parseInt(date[2])
  );
  res.setUTCHours(
    parseInt(hour[0]),
    parseInt(hour[1]),
    parseInt(hour[2] ?? "0")
  );
  return res;
}

export function isISODateTime(date: string): boolean {
  const regex =
    /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]{1,5})?(Z|[+-][0-9]{2}:[0-9]{2})$/i;
  return regex.test(date);
}

export function isISODate(date: string): boolean {
  const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/i;
  return regex.test(date);
}

export function dateToISODate(date: Date): string {
  return `${date.getUTCFullYear()}-${date.getUTCMonth() < 9 ? "0" : ""}${date.getUTCMonth() + 1}-${date.getUTCDate() < 10 ? "0" : ""}${date.getUTCDate()}`;
}

export function isLargeScreen(size: number[]): boolean {
  return (size[0] > 720 && size[0] < 770) || size[0] > 960;
}
export function isTabletScreen(size: number[]): boolean {
  return size[0] >= 640 && size[0] < 1200;
}
export function isMobileScreen(size: number[]): boolean {
  return size[0] < 640;
}
export function normalizeMailInput(input: string) {
  return input.toLowerCase().replace(" ", "");
}