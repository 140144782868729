import { useCallback, useState } from "react";

export type reloadToggle = boolean;

export type DoReload = () => void;

interface UseReload {
  reload: reloadToggle;
  doReload: DoReload;
}

export default function useReload(): UseReload {
  const [reload, setReload] = useState<reloadToggle>(false);
  return {
    reload: reload,
    doReload: useCallback(() => {
      setReload((prev) => !prev);
    }, []),
  };
}
