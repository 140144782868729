import Modal from "@kamae-apps/shared/Component/Modal/Modal";
import React, { useState } from "react";
import Spinner from "../Icons/Spinner";
import { TeamData } from "./Teams";
import { APIError } from "@kamae-apps/shared/Types/API";
import { apiRequest } from "@kamae-apps/shared/utils";
import { useToast } from "@kamae-apps/shared/Component/Toast/Context";
import {
  errorToast,
  successToast,
  warningToast,
} from "@kamae-apps/shared/Component/Toast/ToastBuilder";

interface UserDeleteModalProps {
  display: boolean;
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  teamData: TeamData;
}

export default function TeamDeleteModal(props: UserDeleteModalProps) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  return (
    <Modal
      title={"Supprimer une équipe ⚠️"}
      display={props.display}
      setDisplay={props.setDisplay}
      width={"w-3/5"}
    >
      <div className={"flex w-full flex-col items-center px-4"}>
        <p className={"text-center text-xl"}>
          <span className={"text-red-500"}>
            Voulez vous vraiment supprimer l'équipe
          </span>
          <br />
          {props.teamData.name} ?
        </p>
        <div className={"mt-10 mb-4"}>
          <button
            disabled={loading}
            className={
              "mx-2 rounded border-2 border-red-500 px-4 py-2 text-red-500 transition-colors hover:bg-red-500 hover:text-white"
            }
            onClick={() => {
              setLoading(true);
              apiRequest("/teams/" + props.teamData.id, { method: "DELETE" })
                .then(() => {
                  toast.addToast(successToast("Équipe supprimé"));
                  setLoading(false);
                  props.setDisplay(false);
                })
                .catch((err) => {
                  setLoading(false);
                  if (err instanceof APIError) {
                    toast.addToast(
                      warningToast(
                        "L'entreprise possède encore des données, impossible de la supprimer"
                      )
                    );
                  } else {
                    console.error(err);
                    toast.addToast(errorToast("Erreur lors de la suppression"));
                  }
                });
            }}
          >
            {loading && <Spinner />}Supprimer
          </button>
          <button
            className={
              "mx-4 rounded border-2 border-slate-400 px-8 py-2 text-slate-400 transition-colors hover:bg-slate-400 hover:text-white"
            }
            onClick={() => props.setDisplay(false)}
          >
            Annuler
          </button>
        </div>
      </div>
    </Modal>
  );
}
