import React, { useCallback, useEffect, useMemo, useState } from "react";
import TUser from "@kamae-apps/shared/Types/TUser";
import { scopeToString } from "../../Types/Scope";
import UserDeleteModal from "./DeleteModal";
import Button from "@kamae-apps/shared/Component/Button/Button";
import { useApiRequest } from "@kamae-apps/shared/Hooks/useApiRequest";
import { TCompany } from "@kamae-apps/shared/Types/Company/TCompany";
import Table, {
  Column,
  SortedOrder,
} from "@kamae-apps/shared/Component/Table/Table";
import { statusToString } from "../../utils";
import config from "../../variable";
import { getBeltColor } from "@kamae-apps/shared/Types/Belt/Belt";
import { Edit, Trash } from "react-feather";
import ScrollText from "@kamae-apps/shared/Component/ScrollText/ScrollText";
import useReload from "@kamae-apps/shared/Hooks/useReload";
import { useNavigate } from "react-router-dom";
import { UserAddUpdateModal } from "./UserAddUpdateModal/UserAddUpdateModal";
import { Scope } from "@kamae-apps/shared/Types/Scope";

export interface UserData {
  last_name: string;
  first_name: string;
  email: string;
  teamId: number;
  scope: Scope;
  id: number;
  avatar?: string;
  status: number;
  companyId: number;
}

export const emptyUserData: UserData = {
  last_name: "",
  first_name: "",
  scope: Scope.USER,
  teamId: -1,
  email: "",
  id: 0,
  status: 1,
  companyId: -1,
};

export function TUserToUserData(u: TUser): UserData {
  return {
    id: u.id,
    last_name: u.last_name,
    first_name: u.first_name,
    email: u.email,
    avatar: u.avatar,
    companyId: u.company.id,
    scope: u.scope!!,
    status: u.status!!,
    teamId: u.team.id,
  };
}

export default function User() {
  const { reload, doReload } = useReload();
  const cols: Column[] = useMemo(
    () => [
      { title: "Nom", name: "name", size: "auto" },
      { title: "Prénom", name: "first_name", size: "auto" },
      { title: "Mail", name: "email", size: "auto" },
      { title: "Permission", name: "scope" },
      { title: "Statut", name: "status" },
      { title: "Pts", name: "score", size: "37px" },
      { title: "Avatar", name: "avatar", sortable: false },
      { title: "Équipe", name: "team", size: "minmax(0, 150px)" },
      { title: "Entreprise", name: "company", size: "auto" },
      { title: "Modif", name: "", sortable: false, size: "50px" },
      { title: "Suppr", name: "", sortable: false, size: "50px" },
    ],
    []
  );
  const [updateModalDisplay, setUpdateModalDisplay] = useState(false);
  const [deleteModalDisplay, setDeleteModalDisplay] = useState(false);
  const [type, setType] = useState<"add" | "edit">("add");
  const companyCount = useApiRequest<number>("/company/all/count");
  const company = useApiRequest<TCompany[]>(
    "/company/all?limit=" + (companyCount ?? 10),
    [companyCount]
  );
  const [userData, setUserData] = useState<UserData>(emptyUserData);
  const buttonAdd = useMemo(
    () => (
      <Button
        onClick={() => {
          setType("add");
          setUserData(emptyUserData);
          setUpdateModalDisplay(true);
        }}
        className={
          "border-primary text-primary hover:bg-primary grow whitespace-nowrap p-2 transition-colors hover:text-white"
        }
      >
        Ajouter un collaborateur
      </Button>
    ),
    []
  );

  const navigate = useNavigate();

  const line = useCallback(
    (u: TUser) => [
      u.last_name,
      <p className={"mx-2"}>{u.first_name}</p>,
      u.email,
      scopeToString(u.scope!!),
      statusToString(u.status!!),
      u.global_score,
      <img
        src={
          u.avatar !== undefined
            ? config.api + `/avatar/${u.avatar}/${getBeltColor(u.level)}`
            : "avatarLoading.png"
        }
        alt={"avatar"}
        className={"max-h-16"}
      />,
      <ScrollText>{u.team.name}</ScrollText>,
      <div
        className={
          "flex h-full w-full cursor-pointer items-center justify-center"
        }
        onClick={() => navigate(`/companies/${u.company.id}`)}
      >
        <ScrollText>{u.company.name}</ScrollText>
      </div>,
      <button
        onClick={() => {
          setType("edit");
          setUpdateModalDisplay(true);
          setUserData(TUserToUserData(u));
        }}
      >
        <Edit className={"no-fill text-gray-400"} />
      </button>,
      <button
        onClick={() => {
          setDeleteModalDisplay(true);
          setUserData(TUserToUserData(u));
        }}
        className={"self-center justify-self-center"}
      >
        <Trash className={"no-fill text-gray-400"} />
      </button>,
    ],
    [navigate]
  );
  useEffect(() => {
    doReload();
  }, [deleteModalDisplay, updateModalDisplay, doReload]);
  return (
    <div>
      <Table
        url={"/user/all"}
        defaultSort={"id"}
        defaultOrder={SortedOrder.DESC}
        line={line}
        sortableColumn={cols}
        search
        searchPlaceholder={"Rechercher"}
        next={"Page Suivante"}
        previous={"Page Précédente"}
        actions={buttonAdd}
        cellClassName={"!p-0"}
        reload={reload}
      />
      <UserAddUpdateModal
        type={type}
        modalDisplay={updateModalDisplay}
        setModalDisplay={setUpdateModalDisplay}
        userData={userData}
        setUserData={setUserData}
        companyList={company ?? []}
      />
      <UserDeleteModal
        display={deleteModalDisplay}
        setDisplay={setDeleteModalDisplay}
        userData={userData}
      />
    </div>
  );
}
