import React, { useEffect, useState } from "react";
import { useToast } from "@kamae-apps/shared/Component/Toast/Context";
import clsx from "clsx";
import { useApiRequest } from "@kamae-apps/shared/Hooks/useApiRequest";
import { validMail as validMailFormat } from "../../../utils";
import TTeam from "@kamae-apps/shared/Types/Team/TTeam";
import { clone } from "@kamae-apps/shared/utils";
import { UserData } from "../User";
import { CompanyData } from "../../Companies/Companies";
import { TCompany } from "@kamae-apps/shared/Types/Company/TCompany";
import {
  isNilOrBlank,
  validateEmail,
} from "../../../../../reactfront/src/utils";

export const useUserAddUpdateModal = ({
  modalDisplay,
  userData,
  setUserData,
  companyList,
}: {
  type: "add" | "edit";
  modalDisplay: boolean;
  setModalDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  userData: UserData;
  setUserData: React.Dispatch<React.SetStateAction<UserData>>;
  companyList: TCompany[] | CompanyData[];
}) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [userStatus, setUserStatus] = useState<number>(1);

  const mailCss = clsx(
    isEmailValid ? "border-transparent" : "border-red-500 text-red-500",
    "mb-2 mt-4 border-2"
  );

  const avatar = useApiRequest<string[]>("/avatar");
  useEffect(() => {
    setIsFormValid(
      !isNilOrBlank(userData.last_name) &&
        !isNilOrBlank(userData.first_name) &&
        isEmailValid &&
        userData.teamId !== -1 &&
        userData.companyId !== -1
    );
  }, [userData, isEmailValid, modalDisplay]);

  useEffect(() => {
    if (userData.email.length !== 0) {
      setIsEmailValid(validMailFormat(userData.email));
    } else {
      setIsEmailValid(false);
    }
  }, [modalDisplay, userData]);
  const teams = useApiRequest<TTeam[]>("/teams/company/" + userData.companyId, [
    userData.companyId,
  ]);

  const handleEmailChange = (event: any) => {
    setIsEmailValid(validateEmail(userData.email));
    const newEmail = event.target.value;
    setUserData({ ...userData, email: newEmail });
  };

  useEffect(() => {
    if (userData.companyId === -1 && companyList.length === 1) {
      const tmp = clone(userData);
      tmp.companyId = companyList[0].id;
      setUserData(tmp);
    }
    if (userData.teamId === -1 && teams?.length === 1) {
      const tmp = clone(userData);
      tmp.teamId = teams[0].id;
      setUserData(tmp);
    }
  }, [companyList, setUserData, teams, userData]);

  return {
    loading,
    setLoading,
    toast,
    handleEmailChange,
    valid: isFormValid,
    userStatus,
    mailCss,
    avatar,
    teams,
  };
};
