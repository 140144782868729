import { FC, ReactElement } from "react";

type ConditionalRenderProps = {
  condition: boolean;
  children: ReactElement;
};

export const ConditionalRender: FC<ConditionalRenderProps> = ({
  condition,
  children,
}) => {
  return condition ? children : <></>;
};
