import { forwardRef, MouseEventHandler, ReactNode } from "react";
import Button from "./Button";
import clsx from "clsx";

interface PrimaryButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  spinning?: boolean;
  children?: ReactNode;
  title?: string;
  className?: string;
  linkTo?: string;
  tabIndex?: number;
}

const PrimaryButton = forwardRef<HTMLButtonElement, PrimaryButtonProps>(
  (props, ref) => {
    const { linkTo } = props; // destructuring props and separating the linkTo prop
    const css = clsx(
      "focus:outline:none border-primary text-primary hover:bg-primary disabled:border-primary-200 disabled:text-primary-200 whitespace-nowrap bg-white hover:text-white disabled:hover:bg-white",
      props.className
    );

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
      if (linkTo) {
        window.location.href = linkTo; // redirect to the link when clicked
      }
      if (props.onClick) {
        props.onClick(event); // call the original onClick function if it exists
      }
    };

    return (
      <Button
        tabIndex={props.tabIndex}
        ref={ref}
        className={css}
        disabled={props.disabled}
        type={props.type}
        spinning={props.spinning}
        onClick={handleClick}
        title={props.title}
      >
        {props.children}
      </Button>
    );
  }
);

export default PrimaryButton;
