import { v4 } from "uuid";
import clsx from "clsx";

import "./TextArea.css";
import { ChangeEventHandler } from "react";

interface TextAreaProps {
  placeholder?: string;
  className?: string;
  placeholderStyle?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  value?: string;
  maxHeight?: string;
  name?: string;
  rows?: number;
}

export default function TextArea(props: TextAreaProps) {
  const css = clsx(
    "kamae-text-area bg-primary-50 relative mx-4 flex w-3/5 rounded px-4 py-3 drop-shadow focus:outline-none",
    props.className
  );
  const cssPlaceholder = clsx(
    "kamae-text-area-label bg-primary-50 absolute cursor-text rounded p-0.5 transition-all",
    props.placeholderStyle || "text-gray-400"
  );
  const id = v4();
  return (
    <div className={css}>
      <textarea
        id={id}
        className={clsx(
          "kamae-text-area-input custom-scrollbar grow bg-transparent focus:outline-none",
          props.maxHeight
        )}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        onChange={props.onChange}
        value={props.value}
        rows={props.rows}
        required
        name={props.name}
      ></textarea>
      <label
        className={cssPlaceholder}
        htmlFor={id}
      >
        {props.placeholder}
      </label>
    </div>
  );
}
