import { ChangeEventHandler } from "react";
import { v4 } from "uuid";
import clsx from "clsx";
import { Check } from "react-feather";
import "./CheckBox.css";

interface CheckBoxProps {
  value?: string | number | readonly string[];
  checked?: boolean;
  id?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  disabled?: boolean;
}

export default function CheckBox(props: CheckBoxProps) {
  const id = props.id ?? v4();
  return (
    <div className={clsx("h-6 w-6", props.className)}>
      <input
        className={"kamae-checkbox hidden"}
        type={"checkbox"}
        value={props.value}
        id={id}
        onChange={props.onChange}
        disabled={props.disabled}
        checked={props.checked}
      />
      <label
        htmlFor={id}
        className={
          "kamae-label flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg border-2"
        }
      >
        {props.checked === true && (
          <Check
            className={"no-fill text-white"}
            size={16}
            strokeWidth={3}
          />
        )}
      </label>
    </div>
  );
}
