import { FC } from "react";
import Slider from "@kamae-apps/shared/Component/Input/Slider";
import { CompanyData } from "../../Companies";

type NotificationsSectionProps = {
  company: CompanyData;
  onAutomatedEmailChange: (e: any) => void;
};

export const NotificationsSection: FC<NotificationsSectionProps> = ({
  company,
  onAutomatedEmailChange,
}) => {
  return (
    <div className={"flex flex-col gap-4 border-l-2 border-gray-100 pl-3"}>
      <h3 className={"mb-2 w-full text-center text-lg"}>Notifications</h3>
      <div className={"flex w-full justify-start gap-2"}>
        <Slider
          id={"companyHasAutomatedEmailEnabled"}
          checked={company.automated_email_enabled ?? false}
          onChange={onAutomatedEmailChange}
          className={"m-1"}
        />
        <label
          htmlFor={"companyHasAutomatedEmailEnabled"}
          className={"flex grow items-center justify-between"}
        >
          <span className={"mr-2"}>Emails automatiques</span>
        </label>
      </div>
    </div>
  );
};
