import { CompanyData } from "../../Companies";
import { useToast } from "@kamae-apps/shared/Component/Toast/Context";
import { useApiRequest } from "@kamae-apps/shared/Hooks/useApiRequest";
import { TReflexe } from "@kamae-apps/shared/Types/TReflexe";
import React, { useEffect, useState } from "react";
import Slider from "@kamae-apps/shared/Component/Input/Slider";
import { getCategoryName } from "../../../../Types/Category";
import Button from "@kamae-apps/shared/Component/Button/Button";
import { apiRequest } from "@kamae-apps/shared/utils";
import {
  errorToast,
  successToast,
} from "@kamae-apps/shared/Component/Toast/ToastBuilder";

interface CompanyReflexeProps {
  company: CompanyData;
}

export default function CompanyReflexe(props: CompanyReflexeProps) {
  const { company } = props;

  const toast = useToast();
  const countReflexe = useApiRequest<number>("/reflexe/all/count");
  const reflexes = useApiRequest<TReflexe[]>(
    `/reflexe/all/company/${company.id}?sorted=category&limit=` +
      (countReflexe ?? 10),
    [countReflexe]
  );
  const companyReflexe = useApiRequest<TReflexe[]>(
    `/reflexe/company/${company.id}`
  );
  const [check, setCheck] = useState<boolean[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const companyReflexeId = companyReflexe?.map((v) => v.id) ?? [];
    if (
      reflexes !== undefined &&
      (reflexes.length ?? 0 >= (countReflexe ?? 0))
    ) {
      setCheck(reflexes.map((v) => companyReflexeId.includes(v.id)));
    }
  }, [countReflexe, companyReflexe, reflexes]);

  return (
    <div className={"flex w-full flex-col items-center p-3"}>
      <h2 className={"mt-4 text-lg"}>
        Reflexe pour l'entreprise{" "}
        <span className={"text-primary"}>{props.company.name}</span>
      </h2>
      <div className={"my-4 flex flex-col"}>
        {reflexes?.map((v, i) => {
          return (
            <div
              className={"flex"}
              key={i}
            >
              <Slider
                className={"m-1"}
                value={v.id}
                checked={check[i]}
                id={"reflexe" + v.id}
                onChange={() => {
                  setCheck(check.map((v, index) => (i === index ? !v : v)));
                }}
              />
              <label
                htmlFor={"reflexe" + v.id}
                className={"flex grow justify-between"}
              >
                <span className={"mr-2"}>{v.title}</span>{" "}
                <span>{getCategoryName(v.category)}</span>
              </label>
            </div>
          );
        })}
      </div>
      <div className={"flex"}>
        <Button
          className={
            "border-primary-600 text-primary-600 hover:bg-primary disabled:border-primary-200 disabled:text-primary-200 px-8 py-2 transition-colors hover:text-white disabled:bg-white"
          }
          onClick={() => {
            setLoading(true);
            const companyReflexeId = companyReflexe?.map((v) => v.id) ?? [];
            apiRequest("/reflexe/company/" + props.company.id, {
              method: "POST",
              body: check
                .map((v, i) => (v ? reflexes!![i].id : -1))
                .filter((v) => v !== -1 && !companyReflexeId.includes(v)),
            })
              .then(() => {
                reflexes
                  ?.filter(
                    (v, i) => !check[i] && companyReflexeId.includes(v.id)
                  )
                  .forEach((v) => {
                    apiRequest(
                      "/reflexe/" + v.id + "/company/" + props.company.id,
                      { method: "DELETE" }
                    );
                  });
                toast.addToast(successToast("Réflexe mis à jour"));
                setLoading(false);
              })
              .catch((err) => {
                console.error(err);
                toast.addToast(errorToast());
                setLoading(false);
              });
          }}
          disabled={loading}
        >
          Valider
        </Button>
      </div>
    </div>
  );
}
