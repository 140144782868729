import Modal from "@kamae-apps/shared/Component/Modal/Modal";
import React, { useState } from "react";
import Spinner from "../Icons/Spinner";
import { UserData } from "./User";
import { apiRequest } from "@kamae-apps/shared/utils";
import { useToast } from "@kamae-apps/shared/Component/Toast/Context";
import {
  errorToast,
  successToast,
} from "@kamae-apps/shared/Component/Toast/ToastBuilder";

interface UserDeleteModalProps {
  display: boolean;
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  userData: UserData;
}

export default function UserDeleteModal(props: UserDeleteModalProps) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [title, header, toastText, button] = [
    "Supprimer un utilisateur ⚠️",
    "Voulez vous vraiment supprimer l'utilisateur ?",
    "Utilisateur supprimé",
    "Supprimer",
  ];

  return (
    <Modal
      title={title}
      display={props.display}
      setDisplay={props.setDisplay}
      width={"w-3/5"}
    >
      <div className={"flex w-full flex-col items-center px-4"}>
        <p className={"text-center text-xl"}>
          <span className={"text-red-500"}>{header}</span>
          <br />
          {props.userData.last_name} {props.userData.first_name}
          <br />
          {props.userData.email} ?
        </p>
        <div className={"mt-10 mb-4"}>
          <button
            disabled={loading}
            className={
              "mx-2 rounded border-2 border-red-500 px-4 py-2 text-red-500 transition-colors hover:bg-red-500 hover:text-white"
            }
            onClick={() => {
              setLoading(true);
              apiRequest(`/user/${props.userData.id}`, { method: "DELETE" })
                .then(() => {
                  toast.addToast(successToast(toastText));
                  setLoading(false);
                  props.setDisplay(false);
                })
                .catch((err) => {
                  console.error(err);
                  setLoading(false);
                  toast.addToast(errorToast("Erreur lors de la suppression"));
                });
            }}
          >
            {loading && <Spinner />}
            {button}
          </button>
          <button
            className={
              "mx-4 rounded border-2 border-slate-400 px-8 py-2 text-slate-400 transition-colors hover:bg-slate-400 hover:text-white"
            }
            onClick={() => props.setDisplay(false)}
          >
            Annuler
          </button>
        </div>
      </div>
    </Modal>
  );
}
