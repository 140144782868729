import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CompanyData } from "../Companies";
import { apiRequest } from "@kamae-apps/shared/utils";

type CompanyTabsName =
  | "information"
  | "cryptr"
  | "elearning"
  | "reflexe"
  | "users"
  | "stat"
  | "teams"
  | "actions";

interface CompanyParams {
  companyId: number;
}

export const useCompanyPage = () => {
  const params = useParams<keyof CompanyParams>();
  const [company, setCompany] = useState<CompanyData>();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState<CompanyTabsName>("information");
  const [error, setError] = useState(false);

  useEffect(() => {
    apiRequest<CompanyData>(`/company/${params.companyId}`)
      .then((response) => {
        if (response !== null) {
          setCompany(response);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, [params.companyId]);

  return {
    companyId: params.companyId,
    company,
    setCompany,
    navigate,
    loading,
    currentTab,
    setCurrentTab,
    error,
  };
};
