import React, { Suspense } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Sidebar from "../SideBar/Sidebar";
import Loading from "@kamae-apps/shared/Component/Loading/Loading";
import Teams from "../Teams/Teams";
import Companies from "../Companies/Companies";
import Scenario from "../Scenario/Scenario";
import { ToastContainer } from "@kamae-apps/shared/Component/Toast/Toast";
import { ToastContainerContext } from "@kamae-apps/shared/Component/Toast/Context";
import { CompanyPage } from "../Companies/CompanyPage/CompanyPage";
import { useApp } from "./useApp";
import Card from "@kamae-apps/shared/Component/Card/Card";
import { ForbiddenPage } from "../ForbiddenPage/ForbiddenPage";

const Login = React.lazy(() => import("../LoginPage/LoginPage"));
const Home = React.lazy(() => import("../HomePage/HomePage"));
const User = React.lazy(() => import("../User/User"));
const Phishing = React.lazy(() => import("../Phishing/Phishing"));
const PhishingDomains = React.lazy(
  () => import("../PhishingDomains/PhishingDomains")
);
const ELearning = React.lazy(() => import("../ELearning/ELearning"));
const Translation = React.lazy(() => import("../Translation/Translation"));
const Toolbox = React.lazy(() => import("../Toolbox/Toolbox"));

export default function App() {
  const { loaded, toastContainerRef } = useApp();

  return (
    <Routes>
      <Route
        path={"/login"}
        element={
          <Suspense fallback={<Loading />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        element={
          <div className={"flex h-screen w-screen overflow-hidden"}>
            {loaded || <Loading />}
            <Sidebar />
            <div className={"grow overflow-auto"}>
              <ToastContainerContext.Provider value={toastContainerRef}>
                {loaded && <Outlet />}
              </ToastContainerContext.Provider>
              <ToastContainer ref={toastContainerRef} />
            </div>
          </div>
        }
      >
        <Route
          path={"/home"}
          element={
            <React.Suspense fallback={<Loading />}>
              <Home />
            </React.Suspense>
          }
        />
        <Route
          path={"/user"}
          element={
            <React.Suspense fallback={<Loading />}>
              <User />
            </React.Suspense>
          }
        />
        <Route
          path={"/teams"}
          element={
            <React.Suspense fallback={<Loading />}>
              <Teams />
            </React.Suspense>
          }
        />
        <Route path={"/companies"}>
          <Route
            path={""}
            element={
              <React.Suspense fallback={<Loading />}>
                <Companies />
              </React.Suspense>
            }
          />
          <Route
            path={":companyId"}
            element={
              <React.Suspense fallback={<Loading />}>
                <CompanyPage />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path={"/elearning"}
          element={
            <React.Suspense fallback={<Loading />}>
              <ELearning />
            </React.Suspense>
          }
        />
        <Route
          path={"/reflexe"}
          element={
            <div className={"flex h-full w-full items-center justify-center"}>
              <Card
                className={
                  "flex h-1/3 w-1/3 flex-col items-center justify-center text-lg"
                }
              >
                <p>⚠️ La page des fiches réflexes n'existe pas encore ⚠️</p>
                <p>Repasse plus tard !</p>
              </Card>
            </div>
          }
        />
        <Route
          path={"/phishing"}
          element={
            <React.Suspense fallback={<Loading />}>
              <Phishing />
            </React.Suspense>
          }
        />
        <Route
          path={"/domains"}
          element={
            <React.Suspense fallback={<Loading />}>
              <PhishingDomains />
            </React.Suspense>
          }
        />
        <Route
          path={"/campaign"}
          element={
            <React.Suspense fallback={<Loading />}>
              <Scenario />
            </React.Suspense>
          }
        />
        <Route
          path={"/avatar"}
          element={
            <div className={"flex h-full w-full items-center justify-center"}>
              <Card
                className={
                  "flex h-1/3 w-1/3 flex-col items-center justify-center text-lg"
                }
              >
                <p>⚠️ La page des avatars n'existe pas encore ⚠️</p>
                <p>Repasse plus tard !</p>
              </Card>
            </div>
          }
        />
        <Route
          path={"/translation"}
          element={
            <React.Suspense fallback={<Loading />}>
              <Translation />
            </React.Suspense>
          }
        />
        <Route
          path={"/toolbox"}
          element={
            <React.Suspense fallback={<Loading />}>
              <Toolbox />
            </React.Suspense>
          }
        />
      </Route>
      <Route
        path={"/forbidden"}
        element={<ForbiddenPage />}
      />
    </Routes>
  );
}
