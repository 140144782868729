import PrimaryButton from "@kamae-apps/shared/Component/Button/PrimaryButton";

export const ForbiddenPage = () => {
  return (
    <div
      className={
        "flex h-screen w-screen items-center justify-center bg-white bg-contain bg-no-repeat"
      }
      style={{ backgroundImage: 'url("/login-top.png")' }}
    >
      <div className={"flex items-center justify-center"}>
        <div className="flex flex-col gap-4 rounded-2xl bg-slate-50 px-8 pt-12 shadow">
          <div className="flex items-center justify-start gap-3">
            <div className="flex h-48 w-48 items-center justify-center rounded-full bg-white">
              <img
                alt="kamae-san"
                src="/kamae-san.png"
                className="h-44"
              />
            </div>
            <div>
              <p className="text-3xl">Bonjour</p>
              <p className="text-2xl text-orange-400">Contrôle d'identité</p>
            </div>
          </div>
          <div className={"flex flex-col items-center gap-3"}>
            <p className={"text-red-500"}>
              Tu n'as pas les droits pour accèder aux coulisses du dojo Kamaé.
            </p>
            <PrimaryButton
              onClick={() => {
                window.location.assign("https://app.kamae.io");
              }}
            >
              Retourner en lieu sûr
            </PrimaryButton>
          </div>
          <div className="flex justify-center pb-4">
            <img
              alt="logo"
              src="logo_plateforme.png"
              className="h-12"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
