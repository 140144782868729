import React, { useCallback } from "react";
import { Triangle } from "react-feather";
import { SortedOrder } from "./Table";

interface SortableColumnHeadProps {
  title: string;
  name: string;
  order: SortedOrder;
  setOrder: (order: SortedOrder) => void;
  sorted: string;
  setSorted: (value: string) => void;
  defaultSort?: string;
  defaultOrder?: SortedOrder;
}

export default function SortableColumnHead(props: SortableColumnHeadProps) {
  const { title, name, order, setOrder, sorted, setSorted } = props;

  const onSortedChange = useCallback(
    (sortedValue: string, sortedOrder?: SortedOrder) => {
      setSorted(sortedValue);
      setOrder(sortedOrder ?? SortedOrder.ASC);
    },
    [setOrder, setSorted]
  );
  const onColumnClick = useCallback(
    (sortedValue: string) => {
      if (sorted !== sortedValue) {
        onSortedChange(sortedValue);
      } else {
        const currenState = Math.round(
          (order + 1) % (Object.keys(SortedOrder).length / 2)
        );
        setOrder(currenState);
        if (currenState === SortedOrder.None) {
          onSortedChange(
            props.defaultSort ?? "",
            sortedValue === props.defaultSort
              ? SortedOrder.ASC
              : props.defaultOrder
          );
        }
      }
    },
    [
      sorted,
      order,
      setOrder,
      onSortedChange,
      props.defaultSort,
      props.defaultOrder,
    ]
  );

  return (
    <p
      className={"cursor-pointer select-none"}
      onClick={() => onColumnClick(name)}
    >
      {title}
      <span>
        {order !== SortedOrder.None && name === sorted ? (
          order === SortedOrder.ASC ? (
            <Triangle
              className={"ml-1 inline"}
              size={10}
            />
          ) : (
            <Triangle
              className={"ml-1 inline rotate-180"}
              size={10}
            />
          )
        ) : (
          ""
        )}
      </span>
    </p>
  );
}
