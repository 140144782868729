import { apiRequest } from "@kamae-apps/shared/utils";
import {
  TEmailTemplate,
  TLandingPage,
  TScenario,
  TScenarioPreview,
} from "@kamae-apps/shared/Types/TScenario";
import React, { useCallback, useReducer, useState } from "react";
import { AlertCircle, Edit2, Search, Trash } from "react-feather";
import PrimaryButton from "@kamae-apps/shared/Component/Button/PrimaryButton";
import ScrollText from "@kamae-apps/shared/Component/ScrollText/ScrollText";
import EditAddScenario from "./EditAddScenario";
import ViewScenario from "./ViewScenario";
import { DefaultLanguage } from "@kamae-apps/shared/Types/Company/DefaultLanguage";
import Select from "@kamae-apps/shared/Component/Input/Select";
import { useApi } from "@kamae-apps/shared/Hooks/useApi";
import {
  Table,
  TableColumn,
} from "@kamae-apps/shared/Component/NewTable/Table";
import { Nullable } from "@kamae-apps/shared/Types/Nullable";

const emptyTScenario: TScenario = {
  id: 0,
  language_id: 1,
  name: "",
  logo_file: "",
  company_id: undefined,
  landing_page: {
    html: "",
    redirect_url: "",
    url: "",
  },
  email_template: {
    from: "",
    subject: "",
    html: "",
  },
};

export interface Action {
  type: "edit" | "reset" | "set";
  key?: keyof TScenario;
  key1?: keyof TLandingPage;
  key2?: keyof TEmailTemplate;
  value?: any;
}

function reducer(state: TScenario, action: Action): TScenario {
  switch (action.type) {
    case "edit":
      switch (action.key) {
        case "name":
          return { ...state, name: action.value };
        case "language_id":
          return { ...state, language_id: action.value };
        case "logo_file":
          return {
            ...state,
            logo_file: action.value === "" ? null : action.value,
          };
        case "company_id":
          return { ...state, company_id: action.value };
        case "landing_page":
          switch (action.key1) {
            case "html":
              return {
                ...state,
                landing_page: { ...state.landing_page, html: action.value },
              };
            case "url":
              return {
                ...state,
                landing_page: { ...state.landing_page, url: action.value },
              };
            case "redirect_url":
              return {
                ...state,
                landing_page: {
                  ...state.landing_page,
                  redirect_url: action.value,
                },
              };
            default:
              throw new Error();
          }
        case "email_template":
          switch (action.key2) {
            case "from":
              return {
                ...state,
                email_template: { ...state.email_template, from: action.value },
              };
            case "subject":
              return {
                ...state,
                email_template: {
                  ...state.email_template,
                  subject: action.value,
                },
              };
            case "html":
              return {
                ...state,
                email_template: { ...state.email_template, html: action.value },
              };
            default:
              throw new Error();
          }
        default:
          throw new Error();
      }
    case "reset":
      return emptyTScenario;
    case "set":
      return action.value;
  }
}

export default function Scenario() {
  const [state, dispatch] = useReducer(reducer, emptyTScenario);
  const [displayEdit, setDisplayEdit] = useState(false);
  const [displayView, setDisplayView] = useState(false);
  const [scenarioId, setScenarioId] = useState<Nullable<number>>(null);
  const [type, setType] = useState<"add" | "update">("add");

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectedLanguage, setSelectedLanguage] = useState<DefaultLanguage>(
    DefaultLanguage.FR
  );

  const { isLoading, data, reload } = useApi<TScenarioPreview[]>(
    "/phishing/scenarios",
    {
      queryParams: {
        languageId: selectedLanguage,
      },
      deps: [selectedLanguage],
    }
  );

  const changePage = useCallback((targetedPage: number) => {
    setCurrentPage(targetedPage);
  }, []);

  const columns: TableColumn[] = [
    { key: "name", header: "Nom" },
    { key: "email_from", header: "De" },
    { key: "email_subject", header: "Sujet" },
    { key: "page_redirect_to", header: "Redirection" },
    { key: "page_url", header: "URL" },
    { key: "edit", header: "Edit", disableSort: true },
    { key: "view", header: "View", disableSort: true },
    { key: "delete", header: "Delete", disableSort: true },
  ];

  const Rows =
    data?.map((scenario) => ({
      name: <ScrollText>{scenario.name}</ScrollText>,
      email_from: <ScrollText>{scenario.email_from}</ScrollText>,
      email_subject: <ScrollText>{scenario.email_subject}</ScrollText>,
      page_redirect: <ScrollText>{scenario.page_redirect}</ScrollText>,
      page_url: <ScrollText>{scenario.page_url}</ScrollText>,
      edit: (
        <Edit2
          className={"no-fill m-auto cursor-pointer"}
          onClick={() => {
            setDisplayEdit(true);
            setType("update");
            setScenarioId(scenario.id);
          }}
        />
      ),
      view: (
        <Search
          className={"no-fill m-auto flex cursor-pointer"}
          onClick={() => {
            setDisplayView(true);
            setScenarioId(scenario.id);
          }}
        />
      ),
      delete: (
        <DeleteCell
          reload={reload}
          id={scenario.id!}
        />
      ),
    })) ?? [];

  const TableHeader = (
    <>
      <Select
        options={[
          { name: "Français", value: DefaultLanguage.FR },
          { name: "Anglais", value: DefaultLanguage.EN },
          { name: "Espagnol", value: DefaultLanguage.ES },
          { name: "Allemand", value: DefaultLanguage.DE },
        ]}
        className="w-full"
        defaultValue={selectedLanguage}
        placeholder={"Langue des pièges"}
        onChange={(e) => {
          setSelectedLanguage(parseInt(e.target.value));
          reload();
        }}
      />
      <PrimaryButton
        onClick={() => {
          setDisplayEdit(true);
          setType("add");
          dispatch({ type: "reset" });
          setScenarioId(-1);
        }}
      >
        Ajouter un scénario
      </PrimaryButton>
    </>
  );

  return (
    <div>
      <Table
        data={Rows}
        header={TableHeader}
        totalItemCount={data?.length ?? 0}
        changePage={changePage}
        itemsPerPage={10}
        currentPage={currentPage}
        columns={columns}
        isLoading={isLoading}
      />
      <EditAddScenario
        languageId={selectedLanguage}
        scenarioId={scenarioId}
        display={displayEdit}
        setDisplay={setDisplayEdit}
        reload={reload}
        type={type}
        state={state}
        dispatcher={dispatch}
      />
      <ViewScenario
        languageId={selectedLanguage}
        scenarioId={scenarioId}
        display={displayView}
        setDisplay={setDisplayView}
      />
    </div>
  );
}

function DeleteCell(props: { reload: () => void; id: number }) {
  const [confirm, setConfirm] = useState(false);
  if (!confirm) {
    return (
      <div className="flex">
        <Trash
          className={"no-fill m-auto cursor-pointer "}
          onClick={() => {
            setConfirm(true);
          }}
        />
      </div>
    );
  } else {
    return (
      <AlertCircle
        className={"no-fill m-auto text-orange-600 "}
        onClick={() => {
          apiRequest(`/phishing/scenarios/${props.id}`, { method: "DELETE" })
            .then(() => {
              props.reload();
            })
            .catch((err) => {
              console.error(err);
            });
        }}
      />
    );
  }
}
