export enum DefaultLanguage {
  FR = 1,
  EN = 2,
  ES = 3,
  DE = 4,
}

export const LanguageMap: Record<DefaultLanguage, string> = {
  [DefaultLanguage.FR]: "fr",
  [DefaultLanguage.EN]: "en",
  [DefaultLanguage.ES]: "es",
  [DefaultLanguage.DE]: "de",
} as const;
