import Modal from "@kamae-apps/shared/Component/Modal/Modal";
import { useToast } from "@kamae-apps/shared/Component/Toast/Context";
import {
  errorToast,
  successToast,
} from "@kamae-apps/shared/Component/Toast/ToastBuilder";
import { apiRequest } from "@kamae-apps/shared/utils";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../Icons/Spinner";

interface CompanyDeleteModalProps {
  display: boolean;
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
}

export default function CompanyDeleteModal(props: CompanyDeleteModalProps) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const navigate = useNavigate();
  const [title, header, toastText, button] = [
    "Supprimer l'entreprise ⚠️",
    "Voulez vous vraiment supprimer l'entreprise ? (toutes les données associées seront perdues)",
    "Entreprise supprimée",
    "Supprimer",
  ];

  return (
    <Modal
      title={title}
      display={props.display}
      setDisplay={props.setDisplay}
      width={"w-3/5"}
    >
      <div className={"flex w-full flex-col items-center px-4"}>
        <p className={"text-center text-xl"}>
          <span className={"text-red-500"}>{header}</span>
          <br />
        </p>
        <div className={"mt-10 mb-4"}>
          <button
            disabled={loading}
            className={
              "mx-2 rounded border-2 border-red-500 px-4 py-2 text-red-500 transition-colors hover:bg-red-500 hover:text-white"
            }
            onClick={() => {
              setLoading(true);
              apiRequest("/companies/" + props.id, {
                method: "DELETE",
              })
                .then(() => {
                  toast.addToast(successToast(toastText));
                  setLoading(false);
                  props.setDisplay(false);
                  navigate(`/companies`);
                })
                .catch(() => {
                  setLoading(false);
                  toast.addToast(errorToast());
                });
            }}
          >
            {loading && <Spinner />}
            {button}
          </button>
          <button
            className={
              "mx-4 rounded border-2 border-slate-400 px-8 py-2 text-slate-400 transition-colors hover:bg-slate-400 hover:text-white"
            }
            onClick={() => props.setDisplay(false)}
          >
            Annuler
          </button>
        </div>
      </div>
    </Modal>
  );
}
