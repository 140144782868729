import Card from "../Card/Card";
import Input from "../Input/Input";
import Divider from "../Divider/Divider";
import PrimaryButton from "../Button/PrimaryButton";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import SortableColumnHead from "./SortableColumnHead";
import ColumnHead from "./ColumnHead";

import "./Table.css";
import clsx from "clsx";
import { Filter } from "./Filters";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "react-feather";
import { useApiRequest } from "../../Hooks/useApiRequest";

export interface TranslatedTableProps<T> {
  sortableColumn: Column[];
  line: (line: T, index: number) => ReactNode[];
  url: string;
  search?: boolean;
  navigation?: ReactNode;
  actions?: ReactNode;
  reload?: boolean;
  cellClassName?: string;
  filter?: Filter<unknown, unknown>[];
  persistConf?: boolean;
  defaultSort?: string;
  defaultOrder?: SortedOrder;
}

interface TableProps<T> extends TranslatedTableProps<T> {
  searchPlaceholder?: string;
  next?: string;
  previous?: string;
}

export interface Column {
  title: string;
  name: string;
  sortable?: boolean;
  size?: string;
  className?: string;
  disabled?: boolean;
}

export enum SortedOrder {
  ASC,
  DESC,
  None,
}

export function filterToString(filter?: Filter<unknown, unknown>[]) {
  return filter
    ?.map((f) => {
      return f.values
        .map((v) => `${f.name}=${encodeURIComponent(v.value as never)}`)
        .filter((v) => v.length !== 0)
        .join("&");
    })
    .filter((v) => v.length !== 0)
    .join("&");
}

function decodeQueryParams(queryParams: string) {
  const tmp = decodeURI(queryParams)
    .slice(1)
    .split("&")
    .map((v) => v.split("="));
  const res: Record<string, string | string[]> = {};
  tmp.forEach((v) => {
    if (res[v[0]] === undefined) {
      res[v[0]] = v[1];
    } else if (typeof res[v[0]] === "string") {
      res[v[0]] = [res[v[0]] as string, v[1]];
    } else {
      (res[v[0]] as string[]).push(v[1]);
    }
  });
  return res;
}

export default function Table<T>(props: TableProps<T>) {
  const navigate = useNavigate();
  const location = useLocation();
  const [firstLoad, setFirstLoad] = useState(true);
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [pageText, setPageText] = useState("1");
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");
  const [sorted, setSorted] = useState(props.defaultSort ?? "");
  const [order, setOrder] = useState(props.defaultOrder ?? SortedOrder.ASC);
  const filterString = useMemo(
    () => filterToString(props.filter),
    [props.filter]
  );
  const count = useApiRequest<number>(
    `${props.url}/count?search=${search}${filterString !== undefined ? "&" + filterString : ""}`,
    [search, props.reload, filterString],
    { name: "table-count" }
  );

  const canNext = useMemo(() => {
    return page + 1 < (count ?? 0) / pageSize;
  }, [page, count]);
  const canPrev = useMemo(() => {
    return page > 0;
  }, [page]);
  const queryParams = useMemo(
    () =>
      `?offset=${pageSize * page}&limit=${pageSize}${search !== "" ? `&search=${encodeURIComponent(search)}` : ""}${sorted !== "" ? `&sorted=${sorted}&order=${SortedOrder[order]}` : ""}${
        filterString !== undefined ? "&" + filterString : ""
      }`,
    [pageSize, page, search, sorted, order, filterString]
  );
  useEffect(() => {
    if (props.persistConf === true && location.search !== queryParams) {
      navigate(`${location.pathname}${queryParams}`, { replace: true });
    }
  }, [
    queryParams,
    location.pathname,
    navigate,
    location.search,
    props.persistConf,
  ]);
  useEffect(() => {
    if (props.persistConf && firstLoad && location.search !== "") {
      const data = decodeQueryParams(location.search);
      if (data.search !== undefined) {
        setSearch(data.search as string);
        setSearchText(data.search as string);
      }
      if (data.offset !== undefined && data.limit !== undefined) {
        setPage(
          parseInt(data.offset as string) / parseInt(data.limit as string)
        );
        setPageText(
          (
            parseInt(data.offset as string) / parseInt(data.limit as string) +
            1
          ).toString()
        );
      }
      if (data.order !== undefined) {
        if (data.order === "DESC") {
          setOrder(SortedOrder.DESC);
        } else {
          setOrder(SortedOrder.ASC);
        }
      }
      if (data.sorted !== undefined) {
        setSorted(data.sorted as string);
      }
      setFirstLoad(false);
    }
  }, [location.search, firstLoad, props.persistConf]);
  const requestResult = useApiRequest<T[]>(
    `${props.url}${queryParams}`,
    [
      pageSize,
      page,
      props.url,
      search,
      sorted,
      order,
      props.reload,
      filterString,
    ],
    { name: props.url + "table-request" }
  );

  const columnsSize = useMemo(() => {
    return props.sortableColumn
      .filter((column) => !column.disabled)
      .map((v) => {
        return v.size ?? "1fr";
      })
      .join(" ");
  }, [props.sortableColumn]);

  useEffect(() => {
    setPage(0);
    setPageText("1");
  }, [search]);

  return (
    <Card className={"flex flex-col gap-4"}>
      <div
        className={
          "custom-scrollbar horizontal flex w-full flex-col items-center justify-between overscroll-x-auto lg:flex-row"
        }
      >
        <div
          className={
            "flex w-full items-center justify-center lg:justify-between"
          }
        >
          <div className={"hidden md:block"}>{props.navigation}</div>
          {props.search === true && (
            <Input
              className={"grow last:md:grow-0"}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={props.searchPlaceholder ?? ""}
              onSearch={(e) => setSearch(e.target.value)}
            />
          )}
        </div>
        {props.actions}
      </div>
      <div
        className={
          "grid grid-rows-[auto_minmax(0,1fr)] items-center justify-items-center"
        }
        style={{ gridTemplateColumns: columnsSize }}
      >
        {props.sortableColumn.map(
          (column, index) =>
            !column.disabled &&
            (column.sortable !== false ? (
              <SortableColumnHead
                title={column.title}
                name={column.name}
                sorted={sorted}
                setSorted={setSorted}
                order={order}
                setOrder={setOrder}
                key={index}
                defaultSort={props.defaultSort}
                defaultOrder={props.defaultOrder}
              />
            ) : (
              <ColumnHead title={column.title} />
            ))
        )}
        <Divider
          style={{
            gridColumn:
              "span " +
              props.sortableColumn.length +
              " / span " +
              props.sortableColumn.length,
          }}
        />
        {requestResult?.map((value, index) => (
          <>
            <div className={"row group contents"}>
              {props.line(value, index).map(
                (line, lineIndex) =>
                  !props.sortableColumn[lineIndex].disabled && (
                    <div
                      key={lineIndex}
                      className={clsx(
                        props.cellClassName,
                        props.sortableColumn[lineIndex].className,
                        "flex h-full w-full items-center justify-center overflow-hidden whitespace-nowrap p-4 text-center"
                      )}
                    >
                      {line}
                    </div>
                  )
              )}
              <Divider
                style={{
                  gridColumn: `span ${props.sortableColumn.length} / span ${props.sortableColumn.length}`,
                }}
              />
            </div>
          </>
        ))}
      </div>
      <div className={"flex items-center justify-evenly [&>button]:m-0"}>
        <PrimaryButton
          disabled={!canPrev}
          onClick={() => {
            setPage((prev) => {
              setPageText(prev.toString());
              return prev - 1;
            });
          }}
        >
          <span>
            <ChevronLeft className={"no-fill md:hidden"} />
            <span className={"hidden md:block"}>{props.previous}</span>
          </span>
        </PrimaryButton>
        <span className={"cursor-default text-sm"}>
          <input
            value={pageText}
            onChange={(e) => {
              const newPage = parseInt(e.target.value);
              if (!isNaN(newPage)) {
                if (newPage < 1) {
                  setPage(0);
                  setPageText("1");
                } else if (newPage > (count ?? 0) / pageSize) {
                  setPage(Math.floor((count ?? 0) / pageSize));
                  setPageText(Math.ceil((count ?? 0) / pageSize).toString());
                } else {
                  setPage(newPage - 1);
                  setPageText(newPage.toString());
                }
              } else {
                setPageText(e.target.value);
              }
            }}
            className={
              "border-1 xmd:border-transparent xmd:hover:border-gray-300 w-4 rounded border-gray-300 text-center"
            }
          />{" "}
          / {Math.ceil((count ?? 0) / pageSize)}
        </span>
        <PrimaryButton
          disabled={!canNext}
          onClick={() => {
            setPage((prev) => {
              setPageText((prev + 2).toString());
              return prev + 1;
            });
          }}
        >
          <span>
            <ChevronRight className={"no-fill md:hidden"} />
            <span className={"hidden md:block"}>{props.next}</span>
          </span>
        </PrimaryButton>
      </div>
    </Card>
  );
}
