import { Nullable } from "@kamae-apps/shared/Types/Nullable";
import { TFunction } from "i18next";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type CustomTranslateFunction = TFunction | ((key: string) => Nullable<string>);

export type WithTranslationProps = {
  customTranslateFunction?: CustomTranslateFunction;
};

export function withComponentsNamespaceTranslation<P>(Component: FC<P>): FC<P> {
  return withTranslation(Component, "components");
}

function withTranslation<Props>(
  Component: FC<Props>,
  namespace: string
): FC<Props> {
  var customTranslateFunction: CustomTranslateFunction =
    fallbackTranslateFunctionReturnNull;

  return (props: Props) => {
    const { t, ready: canUsei18n } = useTranslation(namespace, {
      useSuspense: false,
    });

    if (canUsei18n) customTranslateFunction = t;

    return (
      <Component
        {...props}
        customTranslateFunction={customTranslateFunction}
      />
    );
  };
}

const fallbackTranslateFunctionReturnNull = () => null;

export default withTranslation;
