import { CompanyData } from "../../Companies";
import Table, {
  Column,
  SortedOrder,
} from "@kamae-apps/shared/Component/Table/Table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TTeam from "@kamae-apps/shared/Types/Team/TTeam";
import { Edit, Trash } from "react-feather";
import { emptyTeamData, TeamData, TTeamToTeamData } from "../../../Teams/Teams";
import { Filter } from "@kamae-apps/shared/Component/Table/Filters";
import TeamAddUpdateModal from "../../../Teams/TeamAddUpdateModal";
import TeamDeleteModal from "../../../Teams/DeleteModal";
import useReload from "@kamae-apps/shared/Hooks/useReload";

interface CompanyTeamProps {
  company: CompanyData;
}

export default function CompanyTeam(props: CompanyTeamProps) {
  const cols: Column[] = useMemo(() => {
    if (!props.company.dir_sync_activated) {
      return [
        { title: "Nom", name: "name", size: "auto" },
        { title: "Points", name: "score", size: "100px" },
        { title: "Nb User", name: "count", size: "100px" },
        { title: "Modif", name: "", sortable: false, size: "150px" },
        { title: "Suppr", name: "", sortable: false, size: "150px" },
      ];
    } else {
      return [
        { title: "Nom", name: "name", size: "auto" },
        { title: "Points", name: "score", size: "100px" },
        { title: "Nb User", name: "count", size: "100px" },
      ];
    }
  }, [props.company.dir_sync_activated]);

  const [updateDisplay, setUpdateDisplay] = useState(false);
  const [deleteDisplay, setDeleteDisplay] = useState(false);
  const [type, setType] = useState<"add" | "update">("add");
  const [teamData, setTeamData] = useState<TeamData>(emptyTeamData);

  const { reload, doReload } = useReload();

  const addButton = (
    <button
      onClick={() => {
        setType("add");
        setTeamData(emptyTeamData);
        setUpdateDisplay(true);
      }}
      className={
        "border-primary text-primary hover:bg-primary mx-8 whitespace-nowrap rounded border-2 py-2 px-4 transition-colors hover:text-white"
      }
    >
      Ajouter une équipe
    </button>
  );

  const filters: Filter<any, any>[] = useMemo(() => {
    return [
      {
        name: "company",
        label: "",
        values: [{ value: props.company.name, content: "" }],
        options: [{ value: props.company.name, content: "" }],
      },
    ];
  }, [props.company.name]);

  const line = useCallback(
    (t: TTeam) => {
      if (!props.company.dir_sync_activated) {
        return [
          t.name,
          t.global_score,
          t.user_count,
          <button
            onClick={() => {
              setType("update");
              setUpdateDisplay(true);
              setTeamData(TTeamToTeamData(t));
            }}
          >
            <Edit className={"no-fill text-gray-400"} />
          </button>,
          <button
            onClick={() => {
              setDeleteDisplay(true);
              setTeamData(TTeamToTeamData(t));
            }}
          >
            <Trash className={"no-fill text-gray-400"} />
          </button>,
        ];
      } else {
        return [t.name, t.global_score, t.user_count];
      }
    },
    [props.company.dir_sync_activated]
  );

  useEffect(() => {
    doReload();
  }, [updateDisplay, deleteDisplay, doReload]);

  return (
    <>
      <Table
        sortableColumn={cols}
        defaultSort={"id"}
        defaultOrder={SortedOrder.DESC}
        line={line}
        url={"/teams/all"}
        actions={props.company.dir_sync_activated || addButton}
        search
        searchPlaceholder={"Rechercher"}
        next={"Page suivante"}
        previous={"Page précédente"}
        filter={filters}
        reload={reload}
      />
      <TeamAddUpdateModal
        type={type}
        modalDisplay={updateDisplay}
        setModalDisplay={setUpdateDisplay}
        teamData={teamData}
        setTeamData={setTeamData}
        company={[props.company]}
      />
      <TeamDeleteModal
        display={deleteDisplay}
        setDisplay={setDeleteDisplay}
        teamData={teamData}
      />
    </>
  );
}
