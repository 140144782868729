import React from "react";
import FR from "@kamae-apps/shared/Component/Flag/FR";
import EN from "@kamae-apps/shared/Component/Flag/EN";
import { BeltColor } from "@kamae-apps/shared/Types/Belt/BeltColor";
import { Target } from "./Components/Phishing/Add/TargetUser";
import TTeam from "@kamae-apps/shared/Types/Team/TTeam";
import { TFunction } from "i18next";
import ES from "@kamae-apps/shared/Component/Flag/ES";
import DE from "@kamae-apps/shared/Component/Flag/DE";
import { formatInTimeZone } from "date-fns-tz";
import { CompanyStatus } from "@kamae-apps/shared/Types/Company/CompanyStatus";

const beltLevelMap = new Map<BeltColor, number>([
  [BeltColor.white, 5],
  [BeltColor.yellow, 11],
  [BeltColor.orange, 16],
  [BeltColor.green, 20],
  [BeltColor.blue, 24],
  [BeltColor.purple, 27],
  [BeltColor.brown, 31],
  [BeltColor.black, 34],
  [BeltColor.red, 37],
  [BeltColor.bronze, 39],
  [BeltColor.silver, 42],
  [BeltColor.gold, 44],
  [BeltColor.god, 10000],
]);

export function getNextLevel(belt: BeltColor) {
  return beltLevelMap.get(belt) ?? 5;
}

export function getTitle(url: string, t: TFunction) {
  switch (url.split("/")[1]) {
    case "home":
      return t("app:header.title.home");
    case "profile":
      return t("app:header.title.profile");
    case "elearning":
      return t("app:header.title.elearning");
    case "challenge":
      return t("app:header.title.elearning");
    case "phishing":
      return t("app:header.title.phishing");
    case "reflexes":
      return t("app:header.title.reflexes");
    case "supervision":
      return t("app:header.title.supervision");
    case "team":
      return t("app:header.title.team");
    case "organization":
      return t("app:header.title.organization");
  }
}

export function formatSQLDate(date: string) {
  const lang = localStorage.getItem("lang") ?? "fr";
  const a = date.split("-").reverse();
  if (lang === "fr") {
    return a.join("/");
  }
  if (lang === "en") {
    const tmp = a[0];
    a[0] = a[1];
    a[1] = tmp;
    return a.join("/");
  }
}

export function formatDate(date: Date, displayHours: boolean = true): string {
  const lang = localStorage.getItem("lang") ?? "fr";
  if (lang !== "en") {
    return formatInTimeZone(
      date,
      "Europe/Paris",
      `dd/MM/yyyy ${displayHours ? "HH:mm" : ""}`
    );
  } else {
    return formatInTimeZone(
      date,
      "Europe/Paris",
      `MM/dd/yyyy ${displayHours ? "hh:mm aaa" : ""}`
    );
  }
}

export function isNil(value: unknown): boolean {
  return value === null || value === undefined;
}

export function isBlank(value: string): boolean {
  return value.trim() === "";
}

export function isNilOrBlank(value: unknown): boolean {
  return isNil(value) || (typeof value === "string" && isBlank(value));
}

export function getPercentage(total: number, value: number): number {
  return (value * 100) / total;
}

export function getBorderColor(className?: string, def = "border-black") {
  const reg = new RegExp("border-[a-z0-9-]+");
  const res = reg.exec(className ?? "");
  if (res !== null) {
    def = res[0];
  }
  return def;
}

export function validateEmail(email: string): boolean {
  return new RegExp("^[\\w\\d.-]+@[\\w\\d.-]+\\.[\\w\\d_-]{2,}$").test(email);
}

export function actualDate(): string {
  const lang = localStorage.getItem("lang") ?? "fr";
  const date = new Date();
  if (lang === "en") {
    return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}`;
  }
  return `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getFullYear()}`;
}

export function getFlag(code: string): JSX.Element {
  switch (code) {
    case "fr":
      return <FR />;
    case "en":
      return <EN />;
    case "es":
      return <ES />;
    case "de":
      return <DE />;
    default:
      return <></>;
  }
}

// Hash a string using SHA-1
export async function sha1(message: string) {
  const msgUint8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest("SHA-1", msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
}

export function dateToJson(date: Date): string {
  return `${date.getFullYear()}-${date.getMonth() < 9 ? "0" : ""}${date.getMonth() + 1}-${date.getDate() < 10 ? "0" : ""}${date.getDate()}`;
}

export function targetToString(target: Target, teams: TTeam[]): string {
  let res: string[] = [];
  res = res.concat(
    target.team_ids.map(
      (v) => `teams=${teams.find((t) => t.id === v)?.name ?? ""}`
    )
  );
  res = res.concat(target.user_levels.map((v) => `level=${v}`));
  res = res.concat(target.included_user_ids.map((v) => `include=${v}`));
  res = res.concat(target.excluded_user_ids.map((v) => `exclude=${v}`));
  return res.join("&");
}

export function getRandomAvatar() {
  const avatarNames = ["woman2", "man1", "daen", "kap1", "groot"];
  return avatarNames[Math.floor(Math.random() * avatarNames.length)];
}

export function shouldThePathBeMemoized(path: string) {
  return path !== "/" && path !== "/login";
}

export const getCompanyPlan = (companyStatus: CompanyStatus): string => {
  switch (companyStatus) {
    case CompanyStatus.Customer:
      return "Essential";
    case CompanyStatus.Discovery:
      return "Starter";
    case CompanyStatus.Trial:
      return "Trial";
    case CompanyStatus.Kamae:
      return "Kamae";
    case CompanyStatus.Churned:
      return "Churned";
    default:
      return "Unknown";
  }
};
