import { createContext, RefObject, useContext, useMemo } from "react";
import { ToastContainer, ToastInterface, ToastOptions } from "./Toast";

export const ToastContainerContext =
  createContext<RefObject<ToastContainer> | null>(null);

export function useToast(): ToastInterface {
  const toastContainer = useContext(ToastContainerContext);
  return useMemo(() => {
    return {
      addToast: (toast: ToastOptions) => {
        toastContainer?.current?.addToast(toast);
      },
    };
  }, [toastContainer]);
}
