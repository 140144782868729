import Card from "@kamae-apps/shared/Component/Card/Card";
import PrimaryButton from "@kamae-apps/shared/Component/Button/PrimaryButton";
import { useNavigate } from "react-router-dom";

export function CompanyNotFound(props: { id: string | undefined }) {
  const navigate = useNavigate();

  return (
    <div className={"flex h-full w-full items-center justify-center"}>
      <Card className={"flex items-center gap-10 p-10"}>
        <img
          alt="Kamaé-san"
          src="/kamae-san.png"
          className="h-56"
        />
        <div className={"flex flex-col items-center gap-10"}>
          <p>Ce n'est pas l'entreprise que vous recherchez !</p>
          <p>Company_ID « {props.id} » inconnu</p>
          <PrimaryButton onClick={() => navigate("/companies")}>
            Voir la liste des entreprises
          </PrimaryButton>
        </div>
      </Card>
    </div>
  );
}
