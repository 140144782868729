import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TranslationsSync } from "./TranslationSync";
import { PaginatedResponse } from "@kamae-apps/shared/Types/PaginatedResponse";

const jwt = localStorage.getItem("jwt") ?? "";
const headers = { Authorization: `Bearer ${jwt}` };

export const translationSyncApi = createApi({
  reducerPath: "translationSyncApi",
  tagTypes: ["TranslationSync"],
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_REACT_APP_API_URL,
    headers,
  }),
  endpoints: (builder) => ({
    loadTranslationSyncs: builder.query<
      PaginatedResponse<TranslationsSync[]>,
      number
    >({
      query: (page = 0) => `translations/syncs?limit=10&page=${page + 1}`,
      keepUnusedDataFor: Number.MAX_SAFE_INTEGER,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentData, newData) => {
        return {
          payload: newData.payload
            .concat(currentData.payload)
            .filter(
              (value, i, array) =>
                array.findIndex((v2) => v2.id === value.id) === i
            )
            .sort((a, b) => {
              return b.id - a.id;
            }),
          pagination: newData.pagination,
        } satisfies PaginatedResponse<TranslationsSync[]>;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    importTranslations: builder.mutation<void, void>({
      query: () => ({
        url: `translations/import`,
        method: "POST",
      }),
      invalidatesTags: ["TranslationSync"],
    }),
    exportTranslations: builder.mutation<void, void>({
      query: () => ({
        url: `translations/export`,
        method: "POST",
      }),
      invalidatesTags: ["TranslationSync"],
    }),
    exportScenarios: builder.mutation<void, void>({
      query: () => ({
        url: `/phishing/scenarios/export`,
        method: "POST",
      }),
      invalidatesTags: ["TranslationSync"],
    }),
    importScenarios: builder.mutation<void, void>({
      query: () => ({
        url: `/phishing/scenarios/import`,
        method: "POST",
      }),
      invalidatesTags: ["TranslationSync"],
    }),
  }),
});

export const {
  useLoadTranslationSyncsQuery,
  useExportTranslationsMutation,
  useImportTranslationsMutation,
  useImportScenariosMutation,
  useExportScenariosMutation,
} = translationSyncApi;
