import Slider from "@kamae-apps/shared/Component/Input/Slider";
import Button from "@kamae-apps/shared/Component/Button/Button";
import React, { FC } from "react";
import { useElearningContainer } from "./useElearningContainer";
import { CompanyData } from "../../../Companies";
import { ElearningCategory } from "../../Components/ElearningCategory";
import Modal from "@kamae-apps/shared/Component/Modal/Modal";
import CalendarInput from "@kamae-apps/shared/Component/Input/CalendarInput";

type ElearningContainerProps = {
  company: CompanyData;
};
export const ElearningContainer: FC<ElearningContainerProps> = ({
  company,
}) => {
  const {
    loading,
    allChecked,
    elearnings,
    check,
    setCheck,
    date,
    setDate,
    width,
    companyModules,
    changeCheckAll,
    saveElearnings,
    displayCalendar,
    setDisplayCalendar,
    elearningUpdateIndex,
    setElearningUpdateIndex,
  } = useElearningContainer(company);

  return (
    <>
      {elearnings && elearningUpdateIndex !== -1 && (
        <Modal
          title={""}
          width={"w-2/3 lg:w-1/3"}
          display={displayCalendar}
          setDisplay={setDisplayCalendar}
        >
          <div
            className={"h-110 flex flex-col items-center justify-start gap-5"}
          >
            <p className={"text-lg"}>
              {elearnings[elearningUpdateIndex].title}
            </p>
            <CalendarInput
              value={date[elearningUpdateIndex ?? 0] ?? new Date()}
              setValue={(value) => {
                setDate(
                  date.map((v, index) =>
                    elearningUpdateIndex === index ? value : v
                  )
                );
                setDisplayCalendar(false);
              }}
              openCalendar={true}
            />
          </div>
        </Modal>
      )}
      <div className={"flex h-full w-full flex-col p-3"}>
        <div className={"flex w-11/12 items-center 2xl:w-1/2"}>
          <Slider
            className={"m-1"}
            checked={allChecked}
            onChange={changeCheckAll}
          />
          <p>Tout sélectionner</p>
          <div className={"grow"} />
        </div>
        <div className={"mt-4 flex h-5/6 justify-evenly p-3"}>
          {elearnings && companyModules ? (
            <>
              {companyModules.some(
                (module) => module.category === "Company"
              ) && (
                <ElearningCategory
                  categoryKey={"Company"}
                  categoryName={"Cybersécurité"}
                  elearnings={elearnings}
                  check={check}
                  setCheck={setCheck}
                  date={date}
                  setDate={setDate}
                  width={width}
                  setDisplayCalendar={setDisplayCalendar}
                  setElearningUpdateIndex={setElearningUpdateIndex}
                />
              )}
              {companyModules.some((module) => module.category === "Home") && (
                <ElearningCategory
                  categoryKey={"Home"}
                  categoryName={"À la maison"}
                  elearnings={elearnings}
                  check={check}
                  setCheck={setCheck}
                  date={date}
                  setDate={setDate}
                  width={width}
                  setDisplayCalendar={setDisplayCalendar}
                  setElearningUpdateIndex={setElearningUpdateIndex}
                />
              )}
              {companyModules.some((module) => module.category === "RGPD") && (
                <ElearningCategory
                  categoryKey={"RGPD"}
                  categoryName={"RGPD"}
                  elearnings={elearnings}
                  check={check}
                  setCheck={setCheck}
                  date={date}
                  setDate={setDate}
                  width={width}
                  setDisplayCalendar={setDisplayCalendar}
                  setElearningUpdateIndex={setElearningUpdateIndex}
                />
              )}
            </>
          ) : (
            <p>Loading</p>
          )}
        </div>
        <div className={"flex justify-center"}>
          <Button
            className={
              "border-primary-600 text-primary-600 hover:bg-primary disabled:border-primary-200 disabled:text-primary-200 px-8 py-2 transition-colors hover:text-white disabled:bg-white"
            }
            onClick={saveElearnings}
            disabled={loading}
          >
            Sauvegarder
          </Button>
        </div>
      </div>
    </>
  );
};
