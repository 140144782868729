import { MouseEventHandler } from "react";
import Button from "./Button";
import clsx from "clsx";

interface CancelButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  cancel: string;
  disabled?: boolean;
}

export default function CancelButton(props: CancelButtonProps) {
  const css = clsx(
    props.className,
    "border-slate-400 bg-white text-slate-400 hover:bg-slate-400 hover:text-white disabled:border-slate-200 disabled:text-slate-200 disabled:hover:bg-white"
  );
  return (
    <Button
      {...props}
      className={css}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.cancel}
    </Button>
  );
}
