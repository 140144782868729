import React, { MouseEventHandler } from "react";
import Button from "./Button";
import clsx from "clsx";

interface DangerButtonProps {
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: string | JSX.Element | JSX.Element[];
  disabled?: boolean;
  spinning?: boolean;
}

export default function DangerButton(props: DangerButtonProps) {
  const css = clsx(
    "border-rose-500 text-rose-500 hover:bg-rose-500 hover:text-white disabled:border-rose-300 disabled:text-rose-300 disabled:hover:bg-white",
    props.className
  );
  return (
    <Button
      onClick={props.onClick}
      className={css}
      disabled={props.disabled}
      spinning={props.spinning}
    >
      {props.children}
    </Button>
  );
}
