import React from "react";
import { TScenario } from "@kamae-apps/shared/Types/TScenario";
import Modal from "@kamae-apps/shared/Component/Modal/Modal";
import { useApi } from "@kamae-apps/shared/Hooks/useApi";
import { Nullable } from "@kamae-apps/shared/Types/Nullable";

interface ViewScenarioProps {
  scenarioId: Nullable<number>;
  languageId: number;
  display: boolean;
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ViewScenario(props: ViewScenarioProps) {
  const { data: scenario } = useApi<TScenario>(
    "/phishing/scenarios/" + props.scenarioId,
    {
      disable: !props.scenarioId,
      deps: [props.scenarioId, props.languageId],
      queryParams: {
        languageId: props.languageId,
      },
    }
  );

  return (
    <Modal
      title={"Visualiser le scénario"}
      display={props.display}
      setDisplay={props.setDisplay}
      width={"w-screen"}
      height={"h-screen"}
    >
      <div className={"flex h-full grow gap-6"}>
        <iframe
          className={"h-full w-1/2"}
          title={"n/a"}
          srcDoc={scenario?.email_template?.html ?? ""}
          sandbox={"allow-scripts allow-same-origin"}
        />
        <iframe
          className={"h-full w-1/2"}
          title={"n/a"}
          srcDoc={scenario?.landing_page?.html ?? "nope"}
          sandbox={"allow-scripts allow-same-origin"}
        />
      </div>
    </Modal>
  );
}
