import clsx from "clsx";

interface SpinnerProps {
  hidden?: boolean;
  className?: string;
  size?: number;
}

export default function Spinner(props: SpinnerProps) {
  const css = clsx(
    "mr-2 inline-block h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent transition-colors",
    props.hidden && "hidden",
    props.className
  );
  return (
    <div
      className={css}
      style={{ height: props.size, width: props.size }}
    />
  );
}
