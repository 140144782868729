import { useApiRequest } from "@kamae-apps/shared/Hooks/useApiRequest";
import React, {
  HTMLInputTypeAttribute,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useToast } from "@kamae-apps/shared/Component/Toast/Context";
import { CompanyData } from "../../Companies";
import { SSOConnection } from "@kamae-apps/shared/Types/Onboarding";
import { apiRequest } from "@kamae-apps/shared/utils";
import { APIError } from "@kamae-apps/shared/Types/API";
import clsx from "clsx";
import Input from "@kamae-apps/shared/Component/Input/Input";
import { isValidDomain } from "../../../../utils";
import PrimaryButton from "@kamae-apps/shared/Component/Button/PrimaryButton";
import CancelButton from "@kamae-apps/shared/Component/Button/CancelButton";
import {
  errorToast,
  successToast,
} from "@kamae-apps/shared/Component/Toast/ToastBuilder";
import Spinner from "@kamae-apps/shared/Component/Button/Spinner";
import Checked from "../../../Icons/Checked";
import UnChecked from "../../../Icons/UnChecked";
import Divider from "@kamae-apps/shared/Component/Divider/Divider";
import { AlertCircle, Clipboard, Eye, EyeOff, XCircle } from "react-feather";
import { LoginMethod } from "@kamae-apps/shared/Types/Company/LoginMethod";
import DangerButton from "@kamae-apps/shared/Component/Button/DangerButton";
import useReload from "@kamae-apps/shared/Hooks/useReload";
import { ToastInterface } from "@kamae-apps/shared/Component/Toast/Toast";
import Select from "@kamae-apps/shared/Component/Input/Select";
import { validateEmail } from "../../../../../../reactfront/src/utils";

interface CompanyCryptrProps {
  company: CompanyData;
}

interface ScimKey {
  enable: boolean;
  key?: string;
}

interface ScimKey {
  enable: boolean;
  key?: string;
}

export default function CompanyCryptr(props: CompanyCryptrProps) {
  const { company } = props;

  const toast = useToast();
  const mailDomains = useApiRequest<string[]>(
    `/company/${company.id}/sso/mail`
  );
  const [domains, setDomains] = useState<string[]>([]);
  const [newDomain, setNewDomain] = useState("");

  useEffect(() => {
    if (mailDomains !== undefined) {
      setDomains(mailDomains);
    }
  }, [mailDomains]);

  return (
    <div>
      <div className={"flex w-full flex-col justify-between"}>
        <h1 className={"mt-2 mb-3 w-full text-center text-xl"}>Domaine mail</h1>
        <div className={"flex h-full flex-col justify-between"}>
          <div>
            <div className={"mt-3 flex items-center gap-2 justify-self-start"}>
              <Input
                onEnterPress={() => {
                  if (isValidDomain(newDomain)) {
                    setDomains(domains.concat(newDomain));
                    setNewDomain("");
                  }
                }}
                className={"!m-0 !w-full"}
                placeholder={"Ajouter un domaine"}
                value={newDomain}
                onChange={(e) => setNewDomain(e.target.value)}
              />
              <PrimaryButton
                disabled={!isValidDomain(newDomain)}
                onClick={() => {
                  setDomains(domains.concat(newDomain));
                  setNewDomain("");
                }}
              >
                Ajouter
              </PrimaryButton>
            </div>
            <div className={"my-3 flex flex-wrap gap-3"}>
              {mailDomains === undefined
                ? "Chargement"
                : domains?.map((v) => (
                    <MailDomain
                      value={v}
                      key={v}
                      remove={(data) => {
                        setDomains(domains.filter((v) => v !== data));
                      }}
                    />
                  ))}
            </div>
          </div>
          <div className={"flex w-full justify-center"}>
            <CancelButton
              cancel={"Rétablir"}
              onClick={() => setDomains(mailDomains ?? [])}
            />
            <PrimaryButton
              onClick={() => {
                apiRequest<{
                  allowed_email_domains: string[];
                }>(`/company/${company.id}/sso/mail`, {
                  method: "PUT",
                  body: domains,
                })
                  .then((resp) => {
                    if (resp) {
                      setDomains(resp.allowed_email_domains);
                    }
                    toast.addToast(
                      successToast("Domaines mail mis à jour avec succès")
                    );
                  })
                  .catch(() => {
                    toast.addToast(
                      errorToast(
                        "Impossible de mettre les domaines mail à jour"
                      )
                    );
                  });
              }}
            >
              Sauvegarder
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className={"flex justify-evenly p-3"}>
        {company.loginMethod === LoginMethod.MagicLink && (
          <MagicLink company={company} />
        )}
        {company.sso_enabled && (
          <SSO
            company={company}
            first={company.loginMethod !== LoginMethod.MagicLink}
            toast={toast}
          />
        )}
        {company.dir_sync_enabled && <Scim company={company} />}
      </div>
    </div>
  );
}

interface MailDomainProps {
  value: string;
  remove: (value: string) => void;
}

export function MailDomain(props: MailDomainProps) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  return (
    <div
      className={
        "border-1 border-primary bg-primary-50 flex rounded-full py-1 px-2"
      }
    >
      <p>{props.value}</p>
      <button
        className={"ml-3"}
        onClick={() => {
          if (confirmDelete) {
            props.remove(props.value);
          } else {
            setConfirmDelete(true);
          }
        }}
      >
        {confirmDelete ? (
          <AlertCircle className={"no-fill text-red-400"} />
        ) : (
          <XCircle className={"no-fill hover:text-red-400"} />
        )}
      </button>
    </div>
  );
}

interface MagicLinkProps {
  company: CompanyData;
}

function MagicLink(props: MagicLinkProps) {
  const { reload, doReload } = useReload();
  const [creating, setCreating] = useState(false);
  const [noMagicLinkConnection, setNoMagicLinkConnection] = useState(false);

  const onError = useCallback((e: APIError) => {
    if (e.code === 404) {
      setNoMagicLinkConnection(true);
    }
  }, []);

  const magicLinkConnection = useApiRequest(
    `/company/${props.company.id}/magiclink`,
    [reload],
    {
      onError: onError,
      reset: true,
    }
  );

  useEffect(() => {
    if (magicLinkConnection !== undefined) {
      setNoMagicLinkConnection(false);
    }
  }, [magicLinkConnection]);

  return (
    <>
      <div className={"flex w-1/3 flex-col items-center justify-center gap-2"}>
        {magicLinkConnection === undefined && !noMagicLinkConnection ? (
          <p>Loading</p>
        ) : noMagicLinkConnection ? (
          <div className={"relative"}>
            <div
              className={
                "absolute top-[-10%] left-[-5%] h-[120%] w-[110%] animate-pulse rounded bg-orange-500/50 blur"
              }
            />
            <div
              className={
                "relative rounded border-2 border-orange-500 bg-white p-2"
              }
            >
              <p> ⚠️ Attention aucune connexion magic link ⚠️</p>
              <PrimaryButton
                disabled={creating}
                spinning={creating}
                onClick={() => {
                  setCreating(true);
                  apiRequest(`/company/${props.company.id}/magiclink`, {
                    method: "POST",
                  }).then(() => {
                    setTimeout(() => {
                      doReload();
                      setCreating(false);
                    }, 2000);
                  });
                }}
              >
                Créer la connexion magic link
              </PrimaryButton>
            </div>
          </div>
        ) : (
          <>
            <p>Connexion magic link opérationnelle</p>
            <DangerButton
              spinning={creating}
              disabled={creating}
              onClick={() => {
                setCreating(true);
                apiRequest(`/company/${props.company.id}/magiclink`, {
                  method: "DELETE",
                }).then(() => {
                  setTimeout(() => {
                    doReload();
                    setCreating(false);
                  }, 2000);
                });
              }}
            >
              Suppprimer connexion magic link
            </DangerButton>
          </>
        )}
      </div>
    </>
  );
}

interface SSOProps {
  company: CompanyData;
  first: boolean;
  toast: ToastInterface;
}

function SSO(props: SSOProps) {
  const { company, toast } = props;
  const [SSOConnection, setSSOConnection] = useState<SSOConnection>();
  const [SSOConnectionLoading, setSSOConnectionLoading] = useState(true);
  const [SSOConnectionBadRequest, setSSOConnectionBadRequest] = useState(false);
  const [resetSSOLoading, setResetSSOLoading] = useState(false);
  const [newSSOEmail, setNewSSOEmail] = useState("");
  const [isNewAdminEmailValid, setIsNewAdminEmailValid] = useState(true);

  useEffect(() => {
    if (company.sso_enabled) {
      apiRequest<SSOConnection>(`/company/${company.id}/sso`, { method: "GET" })
        .then((response) => {
          if (response) {
            setSSOConnection(response);
            setSSOConnectionLoading(false);
          }
        })
        .catch((e: APIError) => {
          if (e.code !== 404) {
            setSSOConnectionBadRequest(true);
          }
          setSSOConnectionLoading(false);
        });
    } else {
      setSSOConnection(undefined);
      setSSOConnectionBadRequest(false);
      setSSOConnectionLoading(false);
    }
  }, [company]);
  return (
    <>
      {!props.first && <div className={"border-1 border-gray-300"} />}
      <div className={"flex flex-col justify-start gap-2"}>
        <h1 className={"mb-3 w-full text-center text-xl"}>Configuration SSO</h1>
        <div className={"flex flex-col gap-3 p-3"}>
          {SSOConnectionLoading ? (
            <div className={"flex w-full justify-center"}>
              <Spinner
                className={"text-primary"}
                size={40}
              />
            </div>
          ) : (
            <>
              {SSOConnectionBadRequest ? (
                <p className={"w-full text-center text-red-400"}>
                  Erreur lors du chargement des données SSO 😿
                </p>
              ) : (
                <>
                  <div className={"flex gap-2 py-1"}>
                    {SSOConnection ? <Checked /> : <UnChecked />}
                    <p>Une connexion SSO a été créée pour cette compagnie</p>
                  </div>
                  <div className={"flex gap-2 py-1"}>
                    {(SSOConnection?.can_be_activate ||
                      SSOConnection?.activate) &&
                    !SSOConnection?.it_admin_email ? (
                      <Checked />
                    ) : (
                      <UnChecked />
                    )}
                    <p>
                      La configuration SSO est terminée côté Cryptr
                      <br />
                      {SSOConnection?.it_admin_email && (
                        <span className={"text-sm text-gray-400"}>
                          L'invitation a été envoyé à "
                          {SSOConnection.it_admin_email}" le{" "}
                          {new Date(
                            SSOConnection.invitation_sent_at as string
                          ).toLocaleDateString()}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className={"flex gap-2 py-1"}>
                    {SSOConnection?.activate ? <Checked /> : <UnChecked />}
                    <p>
                      Les utilisateurs peuvent se connecter en SSO à la
                      plateforme
                    </p>
                  </div>
                </>
              )}
              <Divider className={""} />
              <div
                className={"flex flex-col items-center justify-evenly gap-3"}
              >
                <h3 className={"text-center text-lg"}>
                  Reset configuration SSO
                </h3>
                <Input
                  className={clsx(
                    "!mx-0 !w-full",
                    !isNewAdminEmailValid ? "border-2 border-red-400" : ""
                  )}
                  placeholderColor={isNewAdminEmailValid ? "" : "text-red-400"}
                  placeholder={"Email admin"}
                  value={newSSOEmail}
                  disabled={
                    resetSSOLoading || typeof SSOConnection === "undefined"
                  }
                  onChange={(e) => {
                    setNewSSOEmail(e.target.value);
                    if (!isNewAdminEmailValid) {
                      setIsNewAdminEmailValid(true);
                    }
                  }}
                />
                {!isNewAdminEmailValid && (
                  <span className={"m-0 w-full text-left text-sm text-red-400"}>
                    L'email admin est invalide
                  </span>
                )}
                <PrimaryButton
                  disabled={
                    resetSSOLoading ||
                    !isNewAdminEmailValid ||
                    typeof SSOConnection === "undefined"
                  }
                  title={"La connexion SSO Cryptr n'a pas encore été créée"}
                  onClick={() => {
                    if (!validateEmail(newSSOEmail)) {
                      setIsNewAdminEmailValid(false);
                      return;
                    }
                    setResetSSOLoading(true);
                    apiRequest<SSOConnection>(`/company/${company.id}/sso`, {
                      method: "PUT",
                      body: { email: newSSOEmail },
                    })
                      .then((response) => {
                        if (response) {
                          setSSOConnection(response);
                          setResetSSOLoading(false);
                        }
                      })
                      .catch(() => {
                        toast.addToast(
                          errorToast("Erreur lors du reset de l'onboarding SSO")
                        );
                        setResetSSOLoading(false);
                      });
                  }}
                >
                  {resetSSOLoading ? (
                    <Spinner className={"text-primary/50 mr-3"} />
                  ) : (
                    ""
                  )}
                  Reset configuration SSO
                </PrimaryButton>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

interface ScimProps {
  company: CompanyData;
}

function Scim(props: ScimProps) {
  const toast = useToast();
  const { reload, doReload } = useReload();
  const { company } = props;
  const [inputType, setInputType] =
    useState<HTMLInputTypeAttribute>("password");
  const input = useRef<HTMLInputElement>(null);
  const urlInput = useRef<HTMLInputElement>(null);
  const scim = useApiRequest<ScimKey>(`/company/${company.id}/dir-sync`, [
    reload,
  ]);
  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState("");
  const options = useMemo(
    () => [
      { value: "azure_ad", name: "Azure AD (Entra)" },
      { name: "Okta", value: "okta" },
      { name: "Ping One", value: "ping_one" },
    ],
    []
  );
  return (
    <>
      <div className={"border-1 border-gray-300"} />
      <div className={"flex w-1/3 flex-col justify-start gap-2"}>
        <h1 className={"text-center text-xl"}>Configuration SCIM</h1>
        <div className={"flex justify-center"}>
          {scim?.enable ? (
            <div>
              <div className={"flex items-center"}>
                <input
                  readOnly
                  ref={urlInput}
                  value={`https://kamae.authent.me/org/${props.company.idp}/scim/v2`}
                  className={"border-1 rounded border-gray-300 p-2"}
                />
                <PrimaryButton
                  className={"!m-2 !px-2"}
                  onClick={() => {
                    urlInput.current?.select();
                    navigator.clipboard.writeText(
                      urlInput.current?.value ?? ""
                    );
                  }}
                >
                  <Clipboard className={"no-fill"} />
                </PrimaryButton>
              </div>
              <div className={"flex items-center"}>
                <input
                  readOnly
                  ref={input}
                  value={scim.key ?? ""}
                  type={inputType}
                  className={"border-1 rounded border-gray-300 p-2"}
                />
                {inputType === "password" ? (
                  <PrimaryButton
                    className={"!m-2 !px-2"}
                    onClick={() => setInputType("text")}
                  >
                    <Eye className={"no-fill"} />
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    className={"!m-2 !px-2"}
                    onClick={() => setInputType("password")}
                  >
                    <EyeOff
                      className={"no-fill"}
                      onClick={() => setInputType("password")}
                    />
                  </PrimaryButton>
                )}
                <PrimaryButton
                  className={"!m-2 !px-2"}
                  onClick={() => {
                    input.current?.select();
                    navigator.clipboard.writeText(scim?.key ?? "");
                  }}
                >
                  <Clipboard className={"no-fill"} />
                </PrimaryButton>
              </div>
              <DangerButton
                disabled={loading}
                spinning={loading}
                onClick={() => {
                  setLoading(true);
                  apiRequest(`/company/${company.id}/dir-sync`, {
                    method: "DELETE",
                  })
                    .then(() => {
                      toast.addToast(successToast("Connexion SCIM supprimée"));
                      doReload();
                      setLoading(false);
                    })
                    .catch(() => {
                      toast.addToast(
                        errorToast("Impossible de supprimer la connexion scim")
                      );
                      setLoading(false);
                    });
                }}
              >
                Supprimer la connexion SCIM
              </DangerButton>
            </div>
          ) : (
            <div className={"flex flex-col items-center"}>
              <Select
                options={options}
                onChange={(e) => {
                  setProvider(e.target.value);
                }}
              />
              <PrimaryButton
                spinning={loading}
                disabled={loading || provider === ""}
                onClick={() => {
                  setLoading(true);
                  apiRequest(
                    `/company/${company.id}/dir-sync?provider=${provider}`,
                    { method: "POST" }
                  )
                    .then(() => {
                      doReload();
                      setLoading(false);
                      setProvider("");
                      toast.addToast(successToast("Connexion SCIM créée"));
                    })
                    .catch(() => {
                      toast.addToast(
                        errorToast("Impossible de créer la connexion SCIM")
                      );
                      setLoading(false);
                    });
                }}
              >
                Créer la connexion SCIM
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
