import React, { useCallback, useEffect, useMemo, useState } from "react";
import TeamAddUpdateModal from "./TeamAddUpdateModal";
import TeamDeleteModal from "./DeleteModal";
import { useApiRequest } from "@kamae-apps/shared/Hooks/useApiRequest";
import { TCompany } from "@kamae-apps/shared/Types/Company/TCompany";
import TTeam from "@kamae-apps/shared/Types/Team/TTeam";
import Table, {
  Column,
  SortedOrder,
} from "@kamae-apps/shared/Component/Table/Table";
import { Edit, Trash } from "react-feather";
import useReload from "@kamae-apps/shared/Hooks/useReload";

export interface TeamData {
  id: number;
  name: string;
  companyId: number;
}

export const emptyTeamData: TeamData = {
  id: 0,
  name: "",
  companyId: -1,
};

export function TTeamToTeamData(t: TTeam): TeamData {
  return {
    id: t.id,
    name: t.name,
    companyId: t.company.id,
  };
}

export default function Teams() {
  const cols: Column[] = useMemo(
    () => [
      { title: "Entreprise", name: "company" },
      { title: "Nom", name: "name" },
      { title: "Points", name: "score" },
      { title: "Nb User", name: "count" },
      { title: "Modif", name: "", sortable: false },
      { title: "Suppr", name: "", sortable: false },
    ],
    []
  );
  const [updateDisplay, setUpdateDisplay] = useState(false);
  const [deleteDisplay, setDeleteDisplay] = useState(false);
  const [type, setType] = useState<"add" | "update">("add");
  const companyCount = useApiRequest<number>("/company/all/count");
  const company = useApiRequest<TCompany[]>(
    "/company/all?limit=" + (companyCount ?? 10),
    [companyCount]
  );
  const [teamData, setTeamData] = useState<TeamData>(emptyTeamData);
  const { reload, doReload } = useReload();
  const addButton = (
    <button
      onClick={() => {
        setType("add");
        setTeamData(emptyTeamData);
        setUpdateDisplay(true);
      }}
      className={
        "border-primary text-primary hover:bg-primary mx-8 rounded border-2 py-2 px-4 transition-colors hover:text-white"
      }
    >
      Ajouter une équipe
    </button>
  );

  useEffect(() => {
    doReload();
  }, [updateDisplay, deleteDisplay, doReload]);

  const line = useCallback((t: TTeam) => {
    return [
      t.company.name,
      t.name,
      t.global_score,
      t.user_count,
      <button
        onClick={() => {
          setType("update");
          setUpdateDisplay(true);
          setTeamData(TTeamToTeamData(t));
        }}
      >
        <Edit className={"no-fill text-gray-400"} />
      </button>,
      <button
        onClick={() => {
          setDeleteDisplay(true);
          setTeamData(TTeamToTeamData(t));
        }}
      >
        <Trash className={"no-fill text-gray-400"} />
      </button>,
    ];
  }, []);

  return (
    <div>
      <Table
        sortableColumn={cols}
        defaultSort={"id"}
        defaultOrder={SortedOrder.DESC}
        line={line}
        url={"/teams/all"}
        actions={addButton}
        search
        searchPlaceholder={"Rechercher"}
        next={"Page suivante"}
        previous={"Page précédente"}
        reload={reload}
      />
      <TeamAddUpdateModal
        type={type}
        modalDisplay={updateDisplay}
        setModalDisplay={setUpdateDisplay}
        teamData={teamData}
        setTeamData={setTeamData}
        company={company ?? []}
      />
      <TeamDeleteModal
        display={deleteDisplay}
        setDisplay={setDeleteDisplay}
        teamData={teamData}
      />
    </div>
  );
}
