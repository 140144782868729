export default function UnChecked(props: {}) {
  return (
    <svg
      className={"shrink-0 text-white"}
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="21"
        height="21"
        rx="2"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
}
