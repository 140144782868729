import React, { useEffect, useMemo, useState } from "react";
import { APIError, APIRequest } from "../Types/API";
import { apiRequest } from "../utils";
import { useTranslation } from "react-i18next";

interface UseApiRequestOption {
  method?: string;
  body?: APIRequest;
  reset?: boolean;
  headers?: Record<string, string>;
  name?: string;
  if?: boolean;
  onError?: (e: APIError) => void;
}

export function useApiRequest<T>(
  url: string,
  deps: React.DependencyList = [],
  options?: UseApiRequestOption
): T | undefined {
  const [data, setData] = useState<T>();
  const { i18n } = useTranslation();
  let stringDeps = JSON.stringify(deps);
  let onError = options?.onError;

  const optionsString = JSON.stringify(options);
  const opt: UseApiRequestOption | undefined = useMemo(
    () => (optionsString !== undefined ? JSON.parse(optionsString) : undefined),
    [optionsString]
  );
  useEffect(() => {
    if (opt?.if === false) {
      return;
    }
    if (opt?.reset ?? false) {
      setData(undefined);
    }
    apiRequest<T>(url, {
      method: opt?.method,
      body: opt?.body,
      name: opt?.name,
      headers: {
        "X-Targeted-Language": i18n.language,
        ...opt?.headers,
      },
    })
      .then((response) => {
        if (response != null) {
          setData(response);
        }
      })
      .catch((err) => {
        if (err instanceof APIError) {
          if (onError !== undefined) {
            onError(err);
          } else {
            console.warn(
              `Error in request. Code : ${err.code}. Message : ${err.message}`
            );
          }
        } else {
          console.error(err);
        }
      });
  }, [url, opt, stringDeps, i18n.language, onError]);
  return data;
}
