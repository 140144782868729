import { Link as RLink } from "react-router-dom";
import clsx from "clsx";

interface LinkProps {
  children: any;
  link: string;
  location: string;
}

export default function Link(props: LinkProps) {
  const current = props.location.startsWith(props.link);
  const css = clsx(
    "my-2 flex w-full cursor-pointer rounded p-2 transition first:mt-0 last:mb-0",
    current ? "bg-primary text-white" : "text-gray-500",
    current || "hover:underline"
  );
  return (
    <RLink
      to={props.link}
      className={css}
    >
      {props.children}
    </RLink>
  );
}
