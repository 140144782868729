import clsx from "clsx";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useWindowSize } from "../../Hooks/useWindowSize";

interface ScrollTextProps {
  children?: ReactNode;
  className?: string;
}

export default function ScrollText(props: ScrollTextProps) {
  const p = useRef<HTMLParagraphElement>(null);
  const [overflow, setOverflow] = useState(false);
  const size = useWindowSize();
  useEffect(() => {
    setOverflow(p.current?.scrollWidth !== p.current?.clientWidth);
  }, [size, p, props.children]);
  return (
    <div
      className={"group/scroll relative justify-self-start overflow-x-hidden"}
    >
      <p
        className={clsx(
          "relative overflow-hidden text-ellipsis whitespace-nowrap group-hover/scroll:overflow-visible group-hover/scroll:text-clip",
          props.className,
          overflow && "group-hover/scroll:animate-scroll"
        )}
        ref={p}
      >
        {props.children}
      </p>
    </div>
  );
}
